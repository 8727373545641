import Swal from 'sweetalert2';

const Message = {
    success: (msg) => {
        Swal.fire({
            title: 'Sucesso',
            text: msg,
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    },
    error: (msg) => {
        Swal.fire({
            title: 'Oops!',
            //text: msg,
            html: msg,
            icon: 'error',
            confirmButtonText: 'Ok'
        });
    },
    infor: (msg) => {
        Swal.fire({
            title: 'Oops!',
            //text: msg,
            html: msg,
            icon: 'info',
            confirmButtonText: 'Ok'
        });
    },
    confirmation: async (msg) => {
        let check = false;
        await Swal.fire({
            title: 'Atenção',
            text: msg,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Sim`,
            denyButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
              check = true;
            } else if (result.isDenied) {
              check = false;
            }
        });
        return check;
    },
    validation: async (error) => {
        //const errors = error.response.data.errors;
        const errors = error.response.data.validation;
        //console.log(errors);
        // VALIDATION SYMFONY
        // errors.map(item => {
        //     Message.error(item);
        // });
        // VALIDATION LARAVEL
        
        Object.keys(errors).map(name => {
            if(errors[name].length) {
                Message.error(errors[name][0]);
            }
        });
    }
}

export default Message;