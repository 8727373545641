import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import Crud from 'src/components/Crud';
import { InputMask, InputText, InputTextarea, Select, InputRadioCheckbox } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import AuthContext from 'src/context/auth';

const FormWrapper = ({ Field, ErrorMessage, errors, values, setFieldValue  }) => {
    const [colaborador, setColaborador] = useState([]);
    const [tabela, setTabela] = useState([]);
    
    
    
    async function getColaborador() {
        const response = await Api('get', 'colaborador/options', '', {});        
        setColaborador(response.data);
    }

    async function getTabelaPreco() {
        const response = await Api('get', 'tabela-precos/options', '', {});        
        setTabela(response.data);
    }

    async function consultCNPJ(){

        let cnpj = values.cnpj.replace('.', '');
        cnpj = cnpj.replace('.', '');
        cnpj = cnpj.replace('.', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.replace('-', '');
        axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
        .then(function (response) {
            if(response.data.status === 'ERROR') {
                Message.error(response.data.message);
            } else {
                setFieldValue('razao_social', response.data.nome);
                setFieldValue('nome_fantasia', response.data.fantasia);
                setFieldValue('fone', response.data.telefone);
                setFieldValue('logradouro', response.data.logradouro);
                setFieldValue('numero', response.data.numero);
                setFieldValue('bairro', response.data.bairro);
                setFieldValue('cidade', response.data.municipio);
                setFieldValue('complemento', response.data.complemento);
                setFieldValue('uf', response.data.uf);
                setFieldValue('cep', response.data.cep);
            }
        })
        .catch(function (error) {
            Message.error("Servidor da Receita Federal possui muitas requisições, por favor, aguarde um momento.");
        });
    }

    useEffect(() => {
        getColaborador();
        getTabelaPreco();
    }, []);

    return (
        <>
            <Row>
                <Col md={1}>
                    <Field 
                        disabled
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col md={6}>
                    <Field 
                        required
                        id="razao_social"
                        label="Razão Social"
                        name="razao_social"
                        component={InputText}
                        error={<ErrorMessage name="razao_social" />}
                    />
                </Col>
                <Col md={5}>
                    <Field 
                        required
                        id="nome_fantasia"
                        label="Nome Fantasia"
                        name="nome_fantasia"
                        component={InputText}
                        error={<ErrorMessage name="nome_fantasia" />}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={3}>
                    <Field 
                        id="cnpj"
                        label="Cnpj"
                        name="cnpj"
                        mask="##.###.###/####-##"
                        component={InputMask}
                        error={<ErrorMessage name="cnpj" />}
                    />
                </Col>
                <Col md={2}>
                    <Button variant="info" onClick={() => consultCNPJ()} style={{ marginTop: '27px' }}>Buscar CNPJ</Button>
                </Col>
                <Col md={2}>
                    <Field 
                        
                        id="cpf"
                        label="Cpf"
                        name="cpf"
                        mask="###.###.###-##"
                        component={InputMask}
                        error={<ErrorMessage name="cpf" />}
                    />
                </Col>
                <Col md={5}>
                    <Field 
                        required
                        id="email"
                        label="Email"
                        name="email"
                        component={InputText}
                        error={<ErrorMessage name="email" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="fone"
                        label="Fone"
                        name="fone"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="fone" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        id="celular"
                        label="Celular"
                        name="celular"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="celular" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        id="telefone"
                        label="Telefone"
                        name="telefone"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="telefone" />}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={5}>
                    <Field 
                        required
                        id="logradouro"
                        label="Endereço"
                        name="logradouro"
                        component={InputText}
                        error={<ErrorMessage name="logradouro" />}
                    />
                </Col>
                <Col md={1}>
                    <Field 
                        required
                        id="numero"
                        label="Número"
                        name="numero"
                        component={InputText}
                        error={<ErrorMessage name="numero" />}
                    />
                </Col>
                <Col md={3}>
                    <Field 
                        id="complemento"
                        label="Complemento"
                        name="complemento"
                        component={InputText}
                        error={<ErrorMessage name="complemento" />}
                    />
                </Col>
                <Col md={3}>
                    <Field 
                        required
                        id="bairro"
                        label="Bairro"
                        name="bairro"
                        component={InputText}
                        error={<ErrorMessage name="bairro" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="cidade"
                        label="Cidade"
                        name="cidade"
                        component={InputText}
                        error={<ErrorMessage name="cidade" />}
                    />
                </Col>
                <Col md={1}>
                    <Field 
                        required
                        id="uf"
                        label="UF"
                        name="uf"
                        component={InputText}
                        error={<ErrorMessage name="uf" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="cep"
                        label="CEP"
                        name="cep"
                        component={InputText}
                        error={<ErrorMessage name="cep" />}
                    />
                </Col>
            </Row>
            <Row>
                
                <Col md={5}>
                    <Field 
                        required
                        id="tabela_id"
                        label="Tabela de Preços"
                        name="tabela_id"
                        placeholder="Selecione"
                        component={Select}
                        list={tabela}
                        error={<ErrorMessage name="tabela_id" />}
                    />
                </Col>
            </Row>
            {/* {JSON.stringify(values, null, 2)} */}
            <Row>
                <Col md={5}>
                    <Field 
                        id="nome_contato"
                        label="Nome do Contato"
                        name="nome_contato"
                        component={InputText}
                        error={<ErrorMessage name="nome_contato" />}
                    />
                </Col>
                <Col md={5}>
                    <Field 
                        id="email_contato"
                        label="E-mail do Contato"
                        name="email_contato"
                        component={InputText}
                        error={<ErrorMessage name="email_contato" />}
                    />
                </Col>
                <Col md={2}>
                    <Field 
                        id="telefone_contato"
                        label="Telefone do Contato"
                        name="telefone_contato"
                        mask="(##) #####.####"
                        component={InputMask}
                        error={<ErrorMessage name="telefone_contato" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Field 
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        placeholder="Selecione"
                        component={InputTextarea}
                        error={<ErrorMessage name="observacao" />}
                    />
                </Col>
            </Row>
        </>
    );
}

export default function ClientsVendedor() {
    const { user } = useContext(AuthContext);

    return (
        <Crud 
            title="Clientes"
            endPoint="clients-vendedor"
            emptyObject={{
                razao_social: '',
                nome_fantasia: '',
                cnpj:  '',
                fone:  '',
                celular: '',
                logradouro:  '',
                numero:  '',
                bairro: '',
                cidade: '',
                uf:  '',
                cep:  '',
                vendedor_id: user.id,
                situacao: false,
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'razao_social', label: 'Razão Social' },
                { name: 'nome_fantasia', label: 'N. Fantasia' },
                { name: 'cnpj', label: 'CNPJ' },
                { name: 'vendedor', label: 'Vendedor' },
                { name: 'vendedor', label: 'Situação' },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td>{item.id}</td>
                    <td>{item.razao_social}</td>
                    <td>{item.nome_fantasia}</td>
                    <td>{item.cnpj}</td>
                    <td>{item.vendedor.name}</td>
                    <td>{item.situacao ? 'Ativo' : 'Inativo'}</td>

                </>
            )}
            validation={(yup) => {
                return {
                    razao_social: yup.string().required("Campo obrigatório"),
                    nome_fantasia: yup.string().required("Campo obrigatório"),
                    fone: yup.string().required("Campo obrigatório"),
                    logradouro: yup.string().required("Campo obrigatório"),
                    numero: yup.string().required("Campo obrigatório"),
                    bairro: yup.string().required("Campo obrigatório"),
                    cidade: yup.string().required("Campo obrigatório"),
                    uf: yup.string().required("Campo obrigatório"),
                    cep: yup.string().required("Campo obrigatório"),
                    vendedor_id: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
