import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputRadioCheckbox, InputText, Select } from 'src/components/FormHelper/Input';
import Message from 'src/core/Message';
// import Api from 'src/core/api';
import Api from 'src/core/api';

const FormWrapper = ({ Field, ErrorMessage, values }) => {
    //const [listCategories, setListCategories] = useState([]);
    const [colaborador, setColaborador] = useState([]);
    async function getCategories() {
        // const response = await Api('get', 'categories/options', '', {});        
        // setListCategories(response.data);
    }

    async function getColaborador() {
        const response = await Api('get', 'colaborador/options', '', {});        
        setColaborador(response.data);
    }

    async function logout() {
        await Api('post', 'logout-user', '', {
            email: values.email
        });        
        Message.success("Usuário desconectado com sucesso.");
    }
   

    useEffect(() => {
        getColaborador();
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <Field 
                        required
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="email"
                        label="E-mail"
                        name="email"
                        component={InputText}
                        error={<ErrorMessage name="email" />}
                    />
                </Col>
                <Col md={1}>
                    <Field 
                        required
                        id="ativo"
                        label="Ativo"
                        name="ativo"
                        component={InputRadioCheckbox}
                        error={<ErrorMessage name="ativo" />}
                    />
                </Col>
                
            </Row>            
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="role"
                        label="Permissão"
                        name="role"
                        placeholder="Selecione"
                        list={[
                            { value: '1', label: 'Administrador' },
                            { value: '2', label: 'Operador' },
                            { value: '3', label: 'Vendedor' },
                        ]}
                        component={Select}
                        error={<ErrorMessage name="role" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="password"
                        label="Senha"
                        name="password"
                        type="password"
                        component={InputText}
                        error={<ErrorMessage name="password" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="confirm_password"
                        label="Confirmação de Senha"
                        name="confirm_password"
                        type="password"
                        component={InputText}
                        error={<ErrorMessage name="confirm_password" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Field 
                        required
                        id="colaborador_id"
                        label="Vendedor"
                        name="colaborador_id"
                        placeholder="Selecione"
                        component={Select}
                        list={colaborador}
                        error={<ErrorMessage name="colaborador_id" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="warning" onClick={logout}>
                        Deslogar de Todos os Aparelhos
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default function Users() {
    return (
        <Crud 
            title="Usuários"
            endPoint="users"
            emptyObject={{
                name: '',
                email: '',
                password: '',
                confirm_password: '',
                ativo: false,
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'name', label: 'Nome' },
                { name: 'email', label: 'E-mail' },
                { name: 'ativo_f', label: 'Situação' }
            ]}
            validation={(yup) => {
                return {
                    email: yup.string().email('E-mail inválido').required("Campo obrigatório"),
                    name: yup.string().required("Campo obrigatório"),
                    password: yup.string(),
                    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'As senhas são diferentes.'),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
