import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'src/components/Modal';
import Api from 'src/core/api';

export default function HistoricosVisitas(props) {
    const [list, setList] = useState([]);

    async function getData(){
        const response = await Api('get', 'visita', '', {
            params: {
                cliente_id: props.cliente_id
            }
        });
        setList(response.data);
    }

    useEffect(() => {
        if(props.show) {
            getData();
        }
    }, [props.show]);
    
    return (
        <>
            <Modal 
                show={props.show}
                size="lg"
                handleClose={() => props.handleClose(null)}
                title="Lista de Visitas"
            >
                <Table hover striped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Cliente</th>
                            <th>Data/Hora Check-in</th>
                            <th>Data/Hora Check-out</th>
                            <th>Vendedor</th>
                            <th>Lat/Lng</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.cliente.razao_social}</td>
                                <td>{item.data_visita_f} {item.hora_check_in}</td>
                                <td>{item.data_visita_f} {item.hora_check_out}</td>
                                <td>{item.vendedor.name}</td>
                                <td>{item.lat_check_in} {item.lat_check_out}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal>
        </>
    );
}

