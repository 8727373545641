import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Tabs, Tab } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText, InputTextarea } from 'src/components/FormHelper/Input';
import Helpers from 'src/core/Helpers';
import Util from 'src/core/Util';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import ReportPdf from 'src/components/ReportPdf';

const FormWrapper = ({ Field, ErrorMessage, values, setFieldValue, handleEdit }) => {
   
    const[codigoPedido, setCodigoPedido] = useState('');
    const [showPdf, setShowPdf] = useState(false);
    const [showPdfProdutos, setShowPdfProdutos] = useState(false);
    const [listStatus, setListStatus] = useState([]);
    const [statusProducaoId, setStatusProducaoId] = useState(null);
    const [listaProdutos, setListaProdutos] = useState([]);

    async function removePedido(pedido_id) {
        const check = await Message.confirmation("Deseja remover este pedido da Ordem de Produção?");
        if(check) {
            await Api('delete', 'ordem-producao/remove-pedido/' + pedido_id, '', {});
            handleEdit({ id: values.id });
        }
    }


    async function addPedido() {
        const check = await Message.confirmation("Deseja adicionar este pedido da Ordem de Produção?");
        if(check) {
            await Api('post', 'ordem-producao/adicionar-pedido-op', '', {
                ordem_producao_id: values.id,
                codigo_pedido: values.codigo
            });
            setFieldValue('codigo', '');
            handleEdit({ id: values.id });
        }
    }

    async function getStatusProducao() {
        if(values.id) {
            const response = await Api('get', `status-producao-por-ordem/${values.id}`, '', {});
            setListStatus(response.data);
        }
    }

    async function getListProdutosByStatus() {
        if(statusProducaoId) {
            const response = await Api('get', `consult-ordens-with-status/${statusProducaoId}/${values.id}`, '', {});
            setListaProdutos(response.data);
        }
    }

    useEffect(() => {
        getListProdutosByStatus();
    }, [statusProducaoId]);

    useEffect(() => {
        getStatusProducao();
    }, []);

    return (
        <>
            <Row>
                <Col md={3}>
                    <Field 
                        id="id"
                        label="Código da Ordem de Serviço"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col md={3}>
                    <Field 
                        id="codigo"
                        label="Código do Pedido"
                        name="codigo"
                        component={InputText}
                        error={<ErrorMessage name="codigo" />}
                    />
                </Col>
                <Col md={4} style={{ paddingTop: '30px' }}>
                    <Button variant="success" size="sm" onClick={() => addPedido()}>Adicionar Pedido a Ordem de Produção</Button>
                </Col>
            </Row> 
            <Row>
                <Col md={12}>
                    <Field 
                        required
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        component={InputTextarea}
                        error={<ErrorMessage name="observacao" />}
                    />
                </Col>
            </Row>
            <Row>
                {!values.pedidos.length && 'Nenhum pedido nesta Ordem de Produção'}
                <Table className={!values.pedidos.length && 'none'}>
                        <tr >
                            <th>Código</th>
                            <th>Status</th>
                            <th>Cliente</th>
                            <th>Data do Pedido</th>
                            <th>Valor Total</th>
                            <th>Ações</th>
                        </tr>
                        {values.pedidos.map((item, index) => (
                            <tr key={index}>
                                <td>{item.codigo}</td>
                                <td>
                                    {Util.getStatusSpan(item.status)} <br />
                                    {item.ordem_producao_correto_id ? 'Em Ordem de Produção' : ''}
                                </td>
                                <td>
                                    <strong>Razão Social: </strong> {item.cliente.razao_social} <br />
                                    <strong>Bairro: </strong> {item.cliente.bairro} - <strong>Cidade: </strong> {item.cliente.cidade} - <strong>Estado: </strong> {item.cliente.uf} <br />
                                    <strong>Valor: </strong>{item.vendedor.name}
                                </td>
                                <td>{item.data}</td>
                                
                                <td>{Helpers.converterFloatReal(item.total)}</td>
                                <td>
                                    <Button 
                                        variant='danger' 
                                        size="sm"
                                        onClick={() => removePedido(item.id)}
                                    >
                                        Excluir
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </Table>
            </Row>     
            <hr />

            <Tabs
                defaultActiveKey="inicio"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                <Tab eventKey="inicio" title="Lista de Produtos">
                    <Row>
                        <ReportPdf
                            title="Ordem de Produção"
                            // endPoint="ordemProducao"
                            endPoint="ordemProducaoStatus"
                            params={{
                                id: values.id,
                            }}
                            show={showPdf}
                            setShow={setShowPdf}
                        />
                        <Col>
                            <h5>Pedidos Agrupados - Lista de Produtos</h5>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button onClick={() => setShowPdf(true)} variant="success" size="sm">Gerar PDF</Button>
                        </Col>
                    </Row>
                    <Row>
                        {!values.produtos_agrupados.length && <p>Nenhum Produto Agrupado</p>}
                        <Table className={!values.produtos_agrupados.length && 'none'}>
                                <tr>
                                    <th>Código</th>
                                    <th>Descrição do Produto</th>
                                    <th>Quantidade</th>
                                </tr>
                                {values.produtos_agrupados.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.produto.id}</td>
                                        <td>
                                            <strong>Título: </strong> {item.produto.titulo} <br />
                                            <strong>Descrição: </strong> {item.produto.descricao} <br />
                                            <strong>Valor: </strong> {item.produto.valor}
                                        </td>
                                        <td>{item.quantidade}</td>
                                        

                                    </tr>
                                ))}
                        </Table>
                    </Row> 
                </Tab>
                
                <Tab eventKey="setores" title="Setores">
                        <ReportPdf
                            title="Pedidos de Produção"
                            endPoint="pedidosProducao"
                            params={{
                                ordem_producao_id: values.id,
                                status_producao_id: statusProducaoId,
                            }}
                            show={showPdfProdutos}
                            setShow={setShowPdfProdutos}
                        />
                    <Tabs
                        defaultActiveKey="0"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onSelect={key => setStatusProducaoId(listStatus[key].status_id)}
                        >
                        
                        {listStatus.map((item, index) => (
                            
                            <Tab eventKey={index} title={item.status.nome.toUpperCase()}>
                                <Row>
                                    <Col md={10}>
                                        Agrupado por: {item.agrupar_por}
                                    </Col>
                                    <Col>
                                        <Button onClick={() => setShowPdfProdutos(true)} variant="success" size="sm">Gerar PDF</Button>
                                    </Col>
                                </Row>
                                
                                
                                <Row>
                                    <Table>
                                            <tr>
                                                <th>Código</th>
                                                <th>Descrição do Produto</th>
                                                <th>Quantidade/Pedido</th>
                                                <th>Quantidade/Estoque</th>
                                                <th>Situação</th>
                                                <th>Qtde</th>
                                            </tr>
                                            {listaProdutos.map((produto, key) => (
                                                <tr key={key}>
                                                    <td>{produto.id}</td>
                                                    <td>
                                                        <strong>Título: </strong> {produto.titulo} <br />
                                                        <strong>Descrição: </strong> {produto.descricao} <br />
                                                        <strong>Valor: </strong> {Helpers.converterFloatReal(produto.valor)}
                                                        {item.status.agrupar_por === 'cor' && 
                                                            <>
                                                                <br />
                                                                <strong>Cor: </strong> {produto.cor}
                                                            </>
                                                        }
                                                    </td>
                                                    <td>{produto._quantidade}</td>
                                                    <td>{produto._estoque}</td>
                                                    <td>{produto._falta > 0 ? 'Falta' : 'Sobra'}</td>
                                                    <td>{produto._falta}</td>
                                                </tr>
                                            ))}
                                    </Table>
                                </Row>
                                
                            </Tab>
                        ))}
                    </Tabs>
                                 
                </Tab>
            </Tabs>  
        </>
    );
}

export default function OrdemProducao() {
    return (
        <Crud 
            title="Ordens de Produções"
            endPoint="ordem-producao"
            searchFieldName='search'
            emptyObject={{
                observacao: '',
                pedidos: [],
                produtos_agrupados: [],
            }}
            fields={[
                { name: 'id', label: 'Código', classBody: 'min-width' },
                { name: 'observacao', label: 'Observação' },
                { name: 'pedidos', label: 'Pedidos' }
            ]}
            validation={(yup) => {
                return {
                    // name: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}

