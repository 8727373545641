import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';


export default function FilterClientOrOrder({ params, setParams }) {
    return (
        <form>
            {/* <Row>
                <Col>
                    <Form.Label>CNPJ</Form.Label>
                    <Form.Control
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        aria-describedby="passwordHelpBlock"
                        placeholder="CNPJ"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
                <Col>
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                        type="text"
                        id="cidade"
                        name="cidade"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Cidade"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
                <Col>
                    <Form.Label>Estado</Form.Label>
                    <Form.Control
                        type="text"
                        id="estado"
                        name="estado"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Estado"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
            </Row> */}
            <Row>
                <Col md={12}>
                    <div className="form-group input-group-sm ">
                        <label>Buscar por Nº Pedido / Cliente / CNPJ / CPF / Endereço / Vendedor / Bairro / Cidade / Estado </label>
                        <input 
                            value={params.search}  
                            onChange={e => setParams({...params, ...{ search: e.target.value }})}
                            className="form-control" 
                            type="text" 
                            name="search" />
                    </div>
                </Col>
               
            </Row>
        </form>
    );
}