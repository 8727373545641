import React, { useState } from 'react';
import { Row, Col, Table, Modal } from 'react-bootstrap';

export default function AlterComissao({ show, setShow, pedido_id }) {

    const [pedidos, setPedidos] = useState([])
    const [modal, setModal] = useState(false);
  
    return (
        <div className="" role="main">
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" style={{ width: '100%' }}>
                    <Row>
                        <Col md={10}>
                            <h4>Alterar Comissão</h4>
                        </Col>
                    </Row>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body id="print">
                    <Table style={{ border: '1px solid  #CCC', width: '100%' }}>
                        
                    </Table>
                </Modal.Body>
            </Modal>
            
            <hr />
        </div>
    );
}
