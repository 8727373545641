import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import Api from 'src/core/api';
import Pagination from 'src/components/Pagination';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import ReportPdf from 'src/components/ReportPdf';
import FilterGlobal from 'src/components/FilterGlobal';
import HistoricosVisitas from './HistoricosVisitas';
import { propTypes } from 'react-currency-format';

export default function UltimasVendas() {

    const [page, setPage] = useState(0);
    const [clientes, setClientes] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({});
    const [historicosShowVisitas ,setHistoricosShowVisitas] = useState(null);
    
    async function getCLientes() {
        const response = await Api('get', 'ultimas-vendas', '', {
            params: {
                page: page,
                ...params
            }
        });        
        
        setClientes(response.list);
        setPagination(response.data);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    useEffect(() => {
        getCLientes();
    }, [page, params]);


    return (
        <div className="" role="main">
            <HistoricosVisitas 
                show={historicosShowVisitas === null ? false : true}
                handleClose={() => setHistoricosShowVisitas(null)}
                cliente_id={historicosShowVisitas}
            />
            <ReportPdf 
                title="Últimas Vendas"
                endPoint="ultimasVendas"
                params={{
                    clientes: selected,
                    ...params,
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            <h4>Últimas Vendas</h4>
            
            <Row>
                <Col>
                    <Button 
                        style={{ float: 'right' }} 
                        size="sm" 
                        variant="success" 
                        onClick={() => setShowPdf(true)}>
                            Gerar PDF
                    </Button>
                </Col>
            </Row>
            <FilterGlobal 
                params={params} 
                setParams={setParams} 
                setSelected={setSelected} 
                list={clientes} 
                isEnableFieldSearch={true}
                isEnableFieldStatus={false}
                isEnableFieldData={false}
                isEnableFieldCodigo={false}
                isEnableFieldAllPedidos={true}
                isEnableFieldAscDesc={true}

            />
            {!clientes.length && 'Nenhum resultado'}
            <Table className={!clientes.length && 'none'}>
                    <tr>
                        <th>Cliente</th>
                        <th>Data do Último Pedido / Dias</th>
                        <th>Data da Última Visita / Dias</th>
                    </tr>
                    {clientes.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td>
                                <strong>Razão Social: </strong> {item.razao_social} <br />
                                <strong>CNPJ/CPF: </strong> {item.cnpj ? item.cnpj : item.cpf} <br />
                                <strong>Bairro: </strong> {item.bairro} - <strong>Cidade: </strong> {item.cidade} - <strong>Estado: </strong> {item.uf} 
                                <br />
                                <strong>Vendedor: </strong> {item.vendedor.name} <br /><br />
                                <strong>Observação de Visita: <br />
                                </strong> {item.visita && item.visita.observacao} <br />
                                <br />
                                <a className="btn btn-warning btn-sm" href onClick={() => setHistoricosShowVisitas(item.id)}>Ver Históricos de Visita</a>
                            </td>
                            
                            <td>{item.venda ? item.venda.data : 'NÃO REALIZOU PEDIDO'} - {item.venda ? item.venda.dias + ' Dias' : ''}</td>
                            <td>{item.visita ? item.visita.data : 'NÃO RECEBEU VISITA'} - {item.visita ? item.visita.dias + ' Dias' : ''}</td>
                            <td>{item.data}</td>
                            {/* <td>{Helpers.converterFloatReal(item.total)}</td> */}
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
        </div>
    );
}
