import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal, Form } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import AlterComissao from './AlterComissao';

export default function ComissoesProducoes() {

    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [params, setParams] = useState({});
        
    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);
  
    async function getPedidos() {
        const response = await Api('get', 'pedidos', '', {
            params: {
                page: page,
                ...params
            }
        });        
        setPedidos(response.data);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    async function agrupar() {
        if(!selected.length) {
            Message.error("Por favor, selecione pelo menos um pedido.");
            return false;
        }
        const response = await Api('get', 'itens-pedidos/agrupar', '', {
            params: {
                pedidos: selected,
            }
        });        
        setProdutos(response.data);
        setShow(true);
    }

    useEffect(() => {
        getPedidos();
    }, [page, params]);

    return (
        <div className="" role="main">
            <h4>Lançamentos de Comissões de Produções</h4>
            <Button size="sm" variant="success" onClick={() => agrupar()}>Lançar Comissão</Button>

            <Row>
                <Col md={6}>
                    <Form.Label>Pesquisar</Form.Label>
                    <Form.Control
                        type="text"
                        id="search"
                        name="search"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Pesquisar"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
                <Col>
                    <Form.Label>Cliente</Form.Label>
                    <Form.Control
                        type="text"
                        id="cliente"
                        name="cliente"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Cliente"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
                <Col>
                    <Form.Label>Vendedor</Form.Label>
                    <Form.Control
                        type="text"
                        id="vendedor"
                        name="vendedor"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Vendedor"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Form.Label>Data Inicial</Form.Label>
                    <Form.Control
                        type="date"
                        id="data_inicio"
                        name="data_inicio"
                        aria-describedby="passwordHelpBlock"  
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}                      
                    />
                </Col>
                <Col>
                    <Form.Label>Data Final</Form.Label>
                    <Form.Control
                        type="date"
                        id="data_fim"
                        name="data_fim"
                        aria-describedby="passwordHelpBlock"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}                        
                    />
                </Col>
                <Col>
                    <Form.Label>CNPJ/CPF</Form.Label>
                    <Form.Control
                        type="text"
                        id="cnpj"
                        name="cnpj"
                        aria-describedby="passwordHelpBlock"
                        placeholder="CNPJ"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
                <Col>
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                        type="text"
                        id="cidade"
                        name="cidade"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Cidade"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
                <Col>
                    <Form.Label>Estado</Form.Label>
                    <Form.Control
                        type="text"
                        id="estado"
                        name="estado"
                        aria-describedby="passwordHelpBlock"
                        placeholder="Estado"
                        onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                    />
                </Col>
            </Row>

            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                <tr>
                    <th>#</th>
                    <th>Dados do Pedido / Cliente</th>
                    <th>Dados do Vendedor</th>
                    <th>Desconto Efetuado Pelo Vendedor</th>
                    <th>Desconto Efetuado Pela Forma de Pagamento</th>
                    <th>Comissão do Vendedor</th>
                    <th>Valor do Pedido</th>
                    <th>Ações</th>
                </tr>
                {pedidos.map((item, index) => (
                    <tr className={selected.includes(item.id) ? 'selected' : ''}>
                        <td onClick={() => handleSelected(item.id)}>
                            <input type="checkbox" checked={selected.includes(item.id) ? true : false} />
                        </td>
                        <td >
                            <h6>Código: {item.codigo}</h6>
                            Status: 
                            {item.status === 'open' && <span className="primary">Aberto</span>}
                            {item.status === 'waiting' && <span className="warning">Aguardando Aprovação</span>}
                            {item.status === 'pending' && <span className="warning">Pendente</span>}
                            {item.status === 'approved' && <span className="success">Aprovado</span>}
                            {item.status === 'production' && <span className="success">Em produção</span>}
                            {item.status === 'travel' && <span className="success">Em Viagem</span>}
                            {item.status === 'finished' && <span className="default">Finalizado</span>}
                            {item.status === 'cancel' && <span className="danger">Cancelado</span>}
                            <br />
                            Data do Pedido:<strong> {item.data}</strong>
                            <hr />
                            <strong>Cliente: </strong>{item.cliente.razao_social}
                        </td>
                        <td>
                            {item.vendedor.name} <br />
                            Comissão: <strong>{item.vendedor.comissao}%</strong>
                        </td>
                        <td>
                            <strong>{item.desconto}%</strong>
                        </td>
                        <td>
                            <strong>{item.item_tipo_pagamento_id ? item.item_tipo_pagamento.desconto : 0}%</strong>
                        </td>
                        <td style={{ color: item.item_tipo_pagamento_id && item.desconto > item.item_tipo_pagamento.desconto ? 'red' : 'green' }}>
                            <strong>{item.vendedor.comissao}%</strong>
                        </td>
                        <td>
                            <strong>{item.total}</strong>
                        </td>
                        <td>
                            <a href className="btn btn-warning btn-sm" onClick={() => setModal(true)}>
                                <FiEdit />
                            </a>
                        </td>
                    </tr>
                ))}
            </Table>
            <hr />
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" style={{ width: '100%' }}>
                    <Row>
                        <Col md={10}>
                            <h4>Pedidos Agrupados</h4>
                        </Col>
                        <Col md={2}>
                            <a href 
                            onClick={() => {
                                var conteudo = document.getElementById('print').innerHTML,
                                tela_impressao = window.open('');
                                tela_impressao.document.write(conteudo);
                                tela_impressao.window.print();
                                tela_impressao.window.close();
                            }}>
                                <h4>Imprimir</h4>
                            </a>
                        </Col>
                    </Row>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body id="print">
                    <Table style={{ border: '1px solid  #CCC', width: '100%' }}>
                        <tr>
                            <th>Descrição</th>
                            <th  style={{ textAlign: 'right' }}>Quantidade</th>
                        </tr>
                        {produtos.map((item, index) => (
                            <tr>
                                <td>{item.produto.titulo}</td>
                                <td style={{ textAlign: 'right' }}>{item.quantidade}</td>
                            </tr>
                        ))}
                        
                    </Table>
                </Modal.Body>
            </Modal>
            <AlterComissao show={modal} setShow={setModal} />
            <hr />
        </div>
    );
}
