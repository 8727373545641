import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputRadioCheckbox, InputText, Select } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';

const FormWrapper = ({ Field, ErrorMessage, values }) => {
  
    const[list, setList] = useState([]);

    async function getStatus() {
        const response = await Api('get', 'status-producao/options', '', {});
        setList(response.data);
    }
    
    useEffect(() => {
        getStatus();
    }, []);

    return (
        <>
            <Row>
                <Col md={1}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="nome"
                        label="Nome"
                        name="nome"
                        component={InputText}
                        error={<ErrorMessage name="nome" />}
                    />
                </Col>
            </Row> 
            <Row> 
                <Col>
                    <Field 
                        required
                        id="agrupar_por"
                        label="Agrupar"
                        name="agrupar_por"
                        component={Select}
                        placeholder="Selecione"
                        list={[
                            { value: 'semi_produto', label: 'Semi Produto' },
                            { value: 'cor', label: 'Cor' },
                        ]}
                        error={<ErrorMessage name="agrupar_por" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="status_producao_id"
                        label="Status Filho"
                        name="status_producao_id"
                        list={list}
                        placeholder="Selecione"
                        component={Select}
                        error={<ErrorMessage name="status_producao_id" />}
                    />
                </Col>
                <Col>
                
                    <Field 
                        required
                        id="is_final"
                        label="Status Final"
                        name="is_final"
                        list={list}
                        isChecked={values.is_final == "1" || values.is_final == 1  ? true : false}
                        placeholder="Selecione"
                        component={InputRadioCheckbox}
                        error={<ErrorMessage name="is_final" />}
                    />
                </Col>
            </Row>         
        </>
    );
}

export default function Status() {
    return (
        <Crud 
            title="Status de Produção"
            endPoint="status-producao"
            searchFieldName='search'
            emptyObject={{
                name: '',
                agrupar_por: 'semi_produto',
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'nome', label: 'Nome' },
                { name: 'nome', label: 'Status Filho' }
            ]}
            fieldsHtml={({ item }) => (
                <>
                    <td>{item.id}</td>
                    <td>{item.nome}</td>
                    <td>{item.status && item.status.nome}</td>
                </>
            )}
            validation={(yup) => {
                return {
                    nome: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
