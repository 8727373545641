import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';

import { InputTextarea } from 'src/components/FormHelper/Input';
import Form from 'src/components/Form';

const FormWrapper = ({ status, values, Field, ErrorMessage }) => {
    
    return (
        <Row>
            
            <Col md={12}>
                <Field
                    id="observacao_status"
                    label="Observação"
                    name="observacao_status"
                    required={status === 'pending' ? true : false}
                    component={InputTextarea}
                    error={<ErrorMessage name="observacao_status" />}
                />
            </Col>
        </Row>
    );
}

export default function AlterarStatus({ pedido_id, status, showStatus, setShowStatus, loadData, handleEdit }) {

    const [data, setData] = useState(null);

    async function handleSubmit(values) {
        await Api('put', `pedidos/${pedido_id}`, '', {
            id: pedido_id,
            status: status,
            observacao_status: values.observacao_status
        });        
        Message.success("Status Alterado com Sucesso");
        setShowStatus(false);
        loadData();
        handleEdit({ id: pedido_id });
        
        
    }

    async function getData() {
        if(pedido_id) {
            const response = await Api('get', `pedidos/${pedido_id}`, '', {});        
            setData(response.data);
        }
        
    }

    useEffect(() => {
        getData();
    }, [pedido_id]);

    return (
        <>
            <Modal
                show={showStatus}
                onHide={() => setShowStatus(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Alterar Status do Pedido
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        data={data}
                        status={status}
                        emptyObject={data}
                        enableBtnCancel={false}
                        validation={(yup) => {
                            if(status === 'pending') {
                                return {
                                    observacao_status: yup.string().required("Campo obrigatório"),
                                }
                            }
                            return null;
                        }}
                        handleSubmit={handleSubmit}
                        FormWrapper={FormWrapper}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}