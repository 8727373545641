import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import  { AiOutlineDollarCircle, AiOutlineNotification } from 'react-icons/ai';
import  { ImFileText2 } from 'react-icons/im';
import  { BsNewspaper } from 'react-icons/bs';
import AuthContext from 'src/context/auth';

export function Submenu({ defaultValue, Content }) {
    
    const [visible, setVisible] = useState(defaultValue || false);
    function handleMenu() {
        setVisible(!visible);
    }
    return (
        <>
            {Content && <Content handleMenu={handleMenu} visible={visible} />}
        </>
    );
}

export function AuthorizationMenuListar({ children, action, btnAction }) {
    const { permissoes } = useContext(AuthContext);
    console.log(permissoes)
    const activePermissionMenu = true;
    
    if(!activePermissionMenu) {
        return children;
    }

    return (
        <>
            {permissoes && permissoes[action] && permissoes[action][btnAction] && 
                <>{children}</>
            }
        
        </>
    )
}

AuthorizationMenuListar.defaultProps = {
    btnAction: 'listar'
}

export default function Menu() { 

    const defaultMenu = {
        cadastro: false,
        producao: false,
        vendas: false,
        financeiro: false,
        relatorios: false,
        autorizacoes: false
    };

    const { permissoes } = useContext(AuthContext);
    const [menuVisible, setMenuVisible] = useState(defaultMenu);

    
    function handleMenu(name, status) {
        setMenuVisible(defaultMenu);
        setMenuVisible({...defaultMenu, ...{ [name]: !status }});
    }
    return (
        <ul className="nav side-menu">
           
            <li>
                <Link to="/">
                    <i className="fa fa-home"></i> Dashboard
                </Link>
            </li>
            
            <AuthorizationMenuListar action="cadastro">
                <Submenu
                    defaultValue={menuVisible.cadastro}
                    Content={({visible}) => {
                        return (
                            <li>
                                <a href onClick={() => handleMenu('cadastro', menuVisible.cadastro)}><i class="fa fa-edit"></i> Cadastros <span className="fa fa-chevron-down"></span></a>
                                <ul className="nav child_menu" style={{ display: menuVisible.cadastro ? 'block' : 'none' }}>
                                
                                    <AuthorizationMenuListar action="cargos">
                                        <li>
                                            <Link to="/cargos">
                                                Cargos
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="cores">
                                        <li>
                                            <Link to="/cores">
                                                Cores
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="colaborador">
                                        <li>
                                            <Link to="/colaborador">
                                                Colaboradores
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="clients">
                                        <li>
                                            <Link to="/clients">
                                                Clientes
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    {/* <li>
                                        <Link to="/table-prices">
                                            Tabela de Preços
                                        </Link>
                                    </li> */}
                                    <AuthorizationMenuListar action="fornecedores">
                                        <li>
                                            <Link to="/fornecedores">
                                                Fornecedor
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="produtos">
                                    <li>
                                        <Link to="/produtos">
                                            Produtos
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="table-prices">
                                    <li>
                                        <Link to="/table-prices">
                                            Tabela de Preços
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="tipo-despesa">
                                    <li>
                                        <Link to="/tipo-despesa">
                                            Tipos de Despesas
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="forma-pagamento">
                                    <li>
                                        <Link to="/forma-pagamento">
                                            Forma de Pagamento
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="comissoes">
                                    <li>
                                        <Link to="/comissoes">
                                            Configurar Comissões
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="status-producao">
                                    <li>
                                        <Link to="/status-producao">
                                            Status de Produção
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                </ul>
                            </li>
                        )
                    }}
                />
            </AuthorizationMenuListar>

            {/* PRODUCAO */}
            <AuthorizationMenuListar action="producao">
                <Submenu
                    defaultValue={menuVisible.producao}
                    Content={({visible}) => {
                        return (
                            <li>
                                <a href onClick={() => handleMenu('producao', menuVisible.producao)}><i class="fa fa-edit"></i> Produção <span className="fa fa-chevron-down"></span></a>
                                <ul className="nav child_menu" style={{ display: menuVisible.producao ? 'block' : 'none' }}>
                                
                                    <AuthorizationMenuListar action="lancar-producao">
                                    <li>
                                        <Link to="/lancar-producao">
                                            Lançar Pedidos p/ Produção
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="ordens-producoes">
                                    <li>
                                        <Link to="/ordens-producoes">
                                            Ordens de Produções
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    {/* <li>
                                        <Link to="/producoes">
                                            Histórico de Produção
                                        </Link>
                                    </li> */}
                                    <AuthorizationMenuListar action="lancar-produzidos">
                                    <li>
                                        <Link to="/lancar-produzidos">
                                            Lançar Produzidos
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="configurar-comissoes">
                                    <li>
                                        <Link to="/configurar-comissoes">
                                            Config. Comissões
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="estoque-producao">
                                    <li>
                                        <Link to="/estoque-producao">
                                            Estoque de Produção
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="estoque-pedido">
                                    <li>
                                        <Link to="/estoque-pedido">
                                            Estoque Vs Ordem P.
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                </ul>
                            </li>
                        )
                    }}
                />
            </AuthorizationMenuListar>
            <AuthorizationMenuListar action="vendas">
                <Submenu
                    defaultValue={menuVisible.vendas}
                    Content={({visible}) => {
                        return (
                            <li>
                                <a href onClick={() => handleMenu('vendas', menuVisible.vendas)}><BsNewspaper size="20" style={{ marginRight: '10px' }} color="white" />{` `} Vendas <span className="fa fa-chevron-down"></span></a>
                                <ul className="nav child_menu" style={{ display: menuVisible.vendas ? 'block' : 'none' }}>
                                    <AuthorizationMenuListar action="orders">
                                        <li>
                                            <Link to="/orders">
                                                Pedidos
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                </ul>
                            </li>
                        )
                    }}
                />
            </AuthorizationMenuListar>
            <AuthorizationMenuListar action="financeiro">
                <Submenu
                    defaultValue={menuVisible.financeiro}
                    Content={({visible}) => {
                        return (
                            <li>
                                <a href onClick={() => handleMenu('financeiro', menuVisible.financeiro)}>
                                    <ImFileText2 size="20" style={{ marginRight: '10px' }} color="white" />{` `} Financeiro <span className="fa fa-chevron-down"></span></a>
                                <ul className="nav child_menu" style={{ display: menuVisible.financeiro ? 'block' : 'none' }}>
                                    <AuthorizationMenuListar action="relatorio-comissoes">
                                        <li>
                                            <Link to="/relatorio-comissoes">
                                                Comissões de Vendas
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="relatorio-producoes">
                                        <li>
                                            <Link to="/relatorio-producoes">
                                                Comissões de Produções
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="lancamentos">
                                        <li>
                                            <Link to="/lancamentos">
                                                Lançamentos
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                </ul>
                            </li>
                        )
                    }}
                />
            </AuthorizationMenuListar>
            <AuthorizationMenuListar action="relatorios">
                <Submenu
                    defaultValue={menuVisible.relatorios}
                    Content={({visible}) => {
                        return (
                            <li>
                                <a href onClick={() => handleMenu('relatorios', menuVisible.relatorios)}><ImFileText2 size="20" style={{ marginRight: '10px' }} color="white" />{` `} Relatórios <span className="fa fa-chevron-down"></span></a>
                                <ul className="nav child_menu" style={{ display: menuVisible.relatorios ? 'block' : 'none' }}>
                                    <AuthorizationMenuListar action="relatorio-pedido">
                                        <li>
                                            <Link to="/relatorio-pedido">
                                                Pedidos
                                            </Link>
                                        </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="relatorio-agrupados">
                                    <li>
                                        <Link to="/relatorio-agrupados">
                                            Agrupados
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="relatorio-romaneio">
                                    <li>
                                        <Link to="/relatorio-romaneio">
                                            Romaneio
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="relatorio-vendas">
                                    <li>
                                        <Link to="/relatorio-vendas">
                                            Vendas
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="ultimas-vendas">
                                    <li>
                                        <Link to="/ultimas-vendas">
                                            Últimas Vendas
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="produtos-mais-vendidos">
                                    <li>
                                        <Link to="/produtos-mais-vendidos">
                                            Produtos Mais Vendidos
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="relatorio-clientes">
                                    <li>
                                        <Link to="/relatorio-clientes">
                                            Clientes
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="relatorio-visitas">
                                    <li>
                                        <Link to="/relatorio-visitas">
                                            Visitas
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    
                                </ul>
                            </li>
                        )
                    }}
                />
            </AuthorizationMenuListar>

            {/* <Submenu
                defaultValue={true}
                Content={({handleMenu, visible}) => {
                    return (
                        <li>
                            <a href onClick={() => handleMenu()}><AiOutlineNotification size="20" style={{ marginRight: '10px' }} color="white" /> Produção <span className="fa fa-chevron-down"></span></a>
                            <ul className="nav child_menu" style={{ display: visible ? 'block' : 'none' }}>
                                <li>
                                    <Link to="/questions">
                                        Perguntas e Respostas
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )
                }}
            /> */}

            {/* <Submenu
                defaultValue={false}
                Content={({handleMenu, visible}) => {
                    return (
                        <li>
                            <a href onClick={() => handleMenu()}><AiOutlineDollarCircle size="20" style={{ marginRight: '10px' }} color="white" /> Financeiro <span className="fa fa-chevron-down"></span></a>
                            <ul className="nav child_menu" style={{ display: visible ? 'block' : 'none' }}>
                                <li>
                                    <Link to="/sales">
                                        Vendas
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )
                }}
            /> */}


            {/* <Submenu
                defaultValue={false}
                Content={({handleMenu, visible}) => {
                    return (
                        <li>
                            <a href onClick={() => handleMenu()}><i class="fa fa-wrench"></i> Utilitários <span className="fa fa-chevron-down"></span></a>
                            <ul className="nav child_menu" style={{ display: visible ? 'block' : 'none' }}>
                                <li>
                                    <Link to="/utilities">
                                        Importações de Produtos
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/migrations">
                                        Migrações ML
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )
                }}
            /> */}
            <AuthorizationMenuListar action="autorizacoes">
                <Submenu
                    defaultValue={menuVisible.autorizacoes}
                    Content={({visible}) => {
                        return (
                            <li>
                                <a href onClick={() => handleMenu('autorizacoes', menuVisible.autorizacoes)}><i class="fa fa-user"></i> Autorizações <span className="fa fa-chevron-down"></span></a>
                                <ul className="nav child_menu" style={{ display: menuVisible.autorizacoes ? 'block' : 'none' }}>
                                    <AuthorizationMenuListar action="users">
                                    <li>
                                        <Link to="/users">
                                            Usuários
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                    <AuthorizationMenuListar action="permissoes">
                                    <li>
                                        <Link to="/permissoes">
                                            Permissões
                                        </Link>
                                    </li>
                                    </AuthorizationMenuListar>
                                </ul>
                            </li>
                        )
                    }}
                />     
            </AuthorizationMenuListar>       
        </ul>
    );
}

