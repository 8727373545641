import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';

import { InputText, InputTextFormat } from 'src/components/FormHelper/Input';
import Form from 'src/components/Form';

const FormWrapper = ({ status, values, Field, ErrorMessage }) => {
    
    return (
        <Row>
            
            <Col md={6}>
                <Field
                    required
                    id="preco"
                    label="Preço"
                    name="preco"
                    component={InputTextFormat}
                    error={<ErrorMessage name="preco" />}
                />
            </Col>
            <Col md={6}>
                <Field
                    required
                    id="quantidade"
                    label="Quantidade"
                    name="quantidade"
                    component={InputText}
                    error={<ErrorMessage name="quantidade" />}
                />
            </Col>
        </Row>
    );
}

export default function AlterarItemPedido({ pedido_id,  item_pedido_id, getItens, status, show, setShow, handleEdit }) {

    const [data, setData] = useState(null);

    async function handleSubmit(values) {
        await Api('put', `itens-pedidos/${item_pedido_id}`, '', {
            id: item_pedido_id,
            ...values
        });        
        Message.success("Status Alterado com Sucesso");
        setShow(false);
        getItens();
        handleEdit({ id: pedido_id });   
        
    }

    async function getData() {
        if(pedido_id) {
            const response = await Api('get', `itens-pedidos/${item_pedido_id}`, '', {});        
            setData(response.data);
        }
        
    }

    useEffect(() => {
        getData();
    }, [item_pedido_id, show]);

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-20w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Alterar Item do Pedido
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        data={data}
                        status={status}
                        emptyObject={data}
                        enableBtnCancel={false}
                        validation={(yup) => {
                            if(status === 'pending') {
                                return {
                                    observacao_status: yup.string().required("Campo obrigatório"),
                                }
                            }
                            return null;
                        }}
                        handleSubmit={handleSubmit}
                        FormWrapper={FormWrapper}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}