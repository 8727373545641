import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText } from 'src/components/FormHelper/Input';
// import Api from 'src/core/api';

const FormWrapper = ({ Field, ErrorMessage }) => {
    // const [listCategories, setListCategories] = useState([]);
    
    async function getCategories() {
        // const response = await Api('get', 'categories/options', '', {});        
        // setListCategories(response.data);
    }

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
            <Row>
                <Col md={1}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Col>
            </Row>            
        </>
    );
}

export default function TipoDespesa() {
    return (
        <Crud 
            title="Tipo de Despesa"
            endPoint="tipo-despesa"
            emptyObject={{
                name: '',
                category_id: '',
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'name', label: 'Nome' }
            ]}
            validation={(yup) => {
                return {
                    name: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
