import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import Api from 'src/core/api';
import Pagination from 'src/components/Pagination';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import ReportPdf from 'src/components/ReportPdf';
import FilterGlobal from 'src/components/FilterGlobal';

export default function ProdutosMaisVendidos() {

    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({});
    
    async function getCLientes() {
        const response = await Api('get', 'maisvendidos', '', {
            params: {
                page: page,
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function handleSelected(itemX) {
        let id = itemX.produto_id;
        console.log(id);
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            console.log(lis);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    useEffect(() => {
        getCLientes();
    }, [page, params]);


    return (
        <div className="" role="main">
            <ReportPdf 
                title="Produtos Mais Vendidos"
                endPoint="produtosMaisVendidos"
                params={{
                    pedidos: selected,
                    ...params,
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            <h4>Produtos Mais Vendidos</h4>
            
            <Row>
                <Col>
                    <Button 
                        style={{ float: 'right' }} 
                        size="sm" 
                        variant="success" 
                        onClick={() => setShowPdf(true)}>
                            Gerar PDF
                    </Button>
                </Col>
            </Row>
            <FilterGlobal 
                params={params} 
                setParams={setParams} 
                setSelected={setSelected} 
                list={pedidos} 
                // isEnableFieldSearch={true}
                isEnableFieldStatus={false}
                isEnableFieldCodigo={false}
                isEnableFieldAllPedidos={true}
                isEnableFieldAscDesc={true}
                titleCheckAll="Todos os Produtos?"

            />
            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                    <tr>
                        <th>Imagem</th>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th className='text-right'>Total</th>
                    </tr>
                    {pedidos.map((item, index) => (
                        <tr onClick={() => handleSelected(item)} className={selected.includes(item.produto_id) ? 'selected' : ''}>
                            <td className="min-width">
                                <img src={item.produto.image || ''} width="110px" />
                            </td>
                            <td>{item.produto.titulo}</td>
                            <td>
                                <strong>{item.quantidade}</strong>
                            </td>
                            <td className='text-right'>
                                <strong>{Helpers.converterFloatReal(item.total)}</strong>
                            </td>
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
        </div>
    );
}
