import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Tabs, Tab, Image } from 'react-bootstrap';
import { Button, InputText } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Message from 'src/core/Message';
import Api from 'src/core/api';

export default function Dependencias({ id }) {
    const [listProdutosItens, setListProdutosItens] = useState([]);

    async function getProdutosItens() {
        if(id) {
            const response = await Api('get', `dependencias/list-produtos/${id}`, '', {});        
            setListProdutosItens(response.data);
        }
    }

    async function handleSave(values) {
        const response = await Api('post', 'dependencias', '', {
            produto_id: id,
            dependencia_id: values.produto_id,
            quantidade: values.quantidade
        });
        Message.success("Dependencia Adicionada com Sucesso.") ;
        getProdutosItens();
    }

    async function deleteItem(item) {
        const check = await Message.confirmation("Deseja Remover o Item?");
        if(check) {
            await Api('delete', `dependencias/${item.id}`, '', {});
            Message.success("Dependencia Removida com Sucesso.") ;
            getProdutosItens();
        }
    }

    useEffect(() => {
        getProdutosItens();
    }, []);

    return (
        <>
            <h6>Adicionar Dependência</h6>
            <Formik
                enableReinitialize
                initialValues={{
                    produto_id: '',
                    quantidade: '',
                }}
                onSubmit={(values) => {
                    handleSave(values)
                }}
            >
                {({ handleSubmit }) => (
                    <Row>
                        <Col md={10}>
                            <label>Produto</label>
                            <Field 
                                endPoint="produtos/options"
                                id="produto_id"
                                label="Selecione o Produto"
                                name="produto_id"
                                component={SelectAutoComplete}
                                error={<ErrorMessage name="produto_id" />}
                            />
                        </Col>
                        <Col md={2}>
                            <Field 
                                required
                                id="quantidade"
                                label="Quantidade"
                                name="quantidade"
                                component={InputText}
                                error={<ErrorMessage name="quantidade" />}
                            />
                            <Button size="sm" variant="success" onClick={handleSubmit} name="Adicionar Dependencia" />
                        </Col>
                    </Row>
                )}
            </Formik>
            

            <Row>
                <Col md={12}>
                    <h6>Items - Dependências</h6>
                    <Table>
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th>Tipo Unidade</th>
                                <th>Quantidade</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listProdutosItens.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.produto_dependencia.titulo}</td>
                                    <td>{item.produto.tipo_unidade}</td>
                                    <td>{item.quantidade}</td>
                                    <td><a href onClick={() => deleteItem(item)}>Excluir</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}