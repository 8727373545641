import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from "react-router-dom";
import AuthContext from 'src/context/auth';
import Body from 'src/layouts/Body';
import Footer from 'src/layouts/Footer';
import Menu from 'src/layouts/Menu';
import TopBar from 'src/layouts/TopBar';
import Login from 'src/pages/Login';


const RouterPrivate = (props) => {
    
    // const navigate = useNavigate();
    const { isLogged, user } = useContext(AuthContext);
    
    
    if(props.isPrivate && !isLogged) {
        // navigate('/login', { replace: true });
        return <Redirect to="login" />;
    }

    if(props.path === '/login' && isLogged) {
        // navigate('/', { replace: true });
        return <Redirect to="/" />;
    }

    if(props.path === '/login') {
        // return <Route path={props.path} element={<Login />} />
        return <Login />;
    }
    
    if(!props.isLayout) {
        return <Route path={props.path} component={props.component} />;
    }
    
    return (
        <>
            <Body>
                
                <div className="col-md-3 left_col">
                    <div className="left_col scroll-view">
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            {document.getElementById("body").classList.contains('nav-sm') ?
                                <img src="images/icon.png" />
                            :   <img src="images/icon.png" />}
                        </div>
                        <div className="navbar nav_title" style={{border: '0', paddingLeft: '37px'}}>
                            <a href className="site_title"> <span>
                                Parlumin - SIS
                            </span></a>
                        </div>
                        
                        <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
                            <div className="profile clearfix">
                                <div className="profile_pic">
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4Y4d_9x8ebL0d0uGM776VrF1Ptwe7vyHDJA&usqp=CAU" alt="..." class="img-circle profile_img" />
                                </div>
                                <div className="profile_info">
                                    <span>Bem vindo,</span>
                                    <h2>{user?.name || ''}</h2>
                                </div>
                            </div>
                            <div className="menu_section">
                                <Menu /> 
                            </div>
                        </div>
                    </div>
                </div>
                <TopBar />
                <div className="right_col" style={{ minHeight: '100vh' }} role="main">
                    <Route path={props.path} component={props.component} />
                </div>
            </Body>
            <Footer />
        </>
    )
}

RouterPrivate.defaultProps = {
    isLayout: true,
};
export default RouterPrivate;