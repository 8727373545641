import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText, Select } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';

const FormWrapper = ({ Field, ErrorMessage }) => {
    const [cargos, setCargos] = useState([]);
    
    async function getCargos() {
        const response = await Api('get', 'cargos/options', '', {});        
        setCargos(response.data);
    }

    useEffect(() => {
        getCargos();
    }, []);

    return (
        <>
            <Row>
                <Col md={1}>
                    <Field 
                        required
                        disabled
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Col>
            </Row>   

            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="email"
                        label="E-mail"
                        name="email"
                        component={InputText}
                        error={<ErrorMessage name="email" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="telefone"
                        label="Telefone"
                        name="telefone"
                        component={InputText}
                        error={<ErrorMessage name="telefone" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="whatsapp"
                        label="WhatsApp"
                        name="whatsapp"
                        component={InputText}
                        error={<ErrorMessage name="whatsapp" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Field 
                        required
                        id="endereco"
                        label="Endereço"
                        name="endereco"
                        component={InputText}
                        error={<ErrorMessage name="endereco" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="cargo_id"
                        label="Cargo"
                        name="cargo_id"
                        placeholder="Selecione"
                        component={Select}
                        list={cargos}
                        error={<ErrorMessage name="cargo_id" />}
                    />
                </Col>
                <Col md={2}>
                    <Field 
                        id="comissao"
                        label="Comissão %"
                        name="comissao"
                        component={InputText}
                        list={cargos}
                        error={<ErrorMessage name="comissao" />}
                    />
                </Col>
            </Row>
        </>
    );
}

export default function Colaborador() {
    return (
        <Crud 
            title="Colaborador"
            endPoint="colaborador"
            searchFieldName="search"
            emptyObject={{
                name: '',
                email: '',
                telefone: '',
                whatsapp: '',
                endereco: '',
                cargo_id: '',
                comissao: 0,
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'name', label: 'Nome' },
                { name: 'email', label: 'E-mail' },
                { name: 'cargo_descricao', label: 'Cargo' },
                { name: 'whatsapp', label: 'WhatsApp' }
            ]}
            validation={(yup) => {
                return {
                    name: yup.string().required("Campo obrigatório"),
                    email: yup.string().required("Campo obrigatório"),
                    telefone: yup.string().required("Campo obrigatório"),
                    whatsapp: yup.string().required("Campo obrigatório"),
                    endereco: yup.string().required("Campo obrigatório"),
                    cargo_id: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
