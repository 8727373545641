import React from 'react';

export default function Body({ children }) {
    return (
        <div className="container body">
            <div className="main_container">
                {children}
            </div>
        </div>
    );
}
