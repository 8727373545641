import React from 'react';
import { BsSearch } from 'react-icons/bs';

export default function InputSearch(props) {
    return (
        <form className="form-inline mr-auto w-100 navbar-search">
            <div className="input-group">
                <input 
                    type="text" 
                    value={props.value}
                    onChange={e => props.handleText(e.target.value)}
                    className="form-control bg-light border-0 small"
                    placeholder="Pesquisar" 
                    aria-label="Pesquisar"
                    aria-describedby="basic-addon2" 
                />
                <div className="input-group-append">
                    <button className="btn btn-primary" onClick={() => props.loadData()} type="button">
                        <BsSearch color="#FFF" />
                    </button>
                </div>
            </div>
        </form>
    );
}