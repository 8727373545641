
import PubSub from "pubsub-js";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table, Modal, Spinner } from "react-bootstrap";
import { AiOutlineFilePdf } from "react-icons/ai";
import HelperContext from "src/context/helper";
import Helpers from "src/core/Helpers";
import Api from '../../core/api';
import LoadingScreen from "../LoadingScreen";

export default function PaperOrder() {

    const { viewPedido, setViewPedido } = useContext(HelperContext);
    const [data, setData] = useState(null);
    const [src, setSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    
    async function getPedido() {
        if(viewPedido) {
            setLoading(true);
            // const response = await Api('get',  `pedidos/${viewPedido}`, '', {});
            // setData(response.data);
            const response = await Api('get',  `relatorios`, '', {
                params: {
                    tipo: 'pedido',
                    pedido_id: viewPedido,
                }
            }, 'blob');
            
            const blob = new Blob([response], {type: 'application/pdf'}); //this make the magic
            const blobURL = URL.createObjectURL(blob);
            const link =  document.createElement('a'); //load content in an iframe to print later
            link.href = blobURL;
            // setSrc(link);

            const a = document.createElement('a');
            // eslint-disable-next-line no-shadow
            const url = window.URL.createObjectURL(
                new Blob([response], { type: 'application/pdf' })
            );
            a.href = url;
            setSrc(a);
            a.remove();
            // link.remove();

            setLoading(false);
        }
    }

    async function print() {
        var conteudo = document.getElementById('content-print').innerHTML,
        tela_impressao = window.open('Pedido');

        tela_impressao.document.write(conteudo);
        tela_impressao.window.print();
        tela_impressao.window.close();
        // window.print();
    }

    useEffect(() => {
        getPedido();
    }, [viewPedido]);
    
    return (
        <>  
            <Modal
                show={viewPedido ? true : false}
                onHide={() => setViewPedido('')}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" style={{ width: '100%' }}>
                    <Row>
                        <Col md={10}>
                            Pedido
                        </Col>
                    </Row>
                    
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        {loading && <Spinner animation="border" variant="warning" />}
                    </div>
                    {!loading && src && 
                        <embed
                            src={src}
                            frameBorder="0"
                            width="100%"
                            height="500px"
                            type="application/pdf"
                        />
                    }
                    {/* {data &&
                    
                        <Container id="content-print" style={{  margin: '20px auto' }}>
                            <img src="http://parlumin.com.br/img/core-img/logo.png" style={{ display: 'block', margin: '0 auto' }} />
                            <Row>
                                <Col>
                                    <strong>
                                        RAZÃO SOCIAL: R L ANDRADE DE SOUSA <br />
                                        ENDEREÇO: R. 18, 1830<br />
                                        MACARACAU CE (85) 3467.7578 <br />
                                        E-MAIL: COMERCIAL@PARLUMIN.COM.BR
                                    </strong>
                                    <br />
                                    <strong>PEDIDO:</strong> {data.codigo || ''} <br />
                                    <strong>EMISSÃO: </strong>{data.today}
                                    
                                    <hr />
                                    <strong>NOME/RAZÃO SOCIAL:</strong> {data.cliente.razao_social} | {data.cliente.nome_fantasia}<br />
                                    <strong>CNPJ / CPF: </strong>{data.cliente.cnpj} | {data.cliente.cpf} <br />
                                    <strong>ENDEREÇO:</strong> {data.cliente.logradouro}, {data.cliente.numero}{` `}
                                    <strong>CEP:</strong>  {data.cliente.cep}  <strong>COMPLEMENTO:</strong>  {data.cliente.complemento}  <br />
                                    <strong>BAIRRO:</strong> {data.cliente.bairro} - <strong>MUNICÍPIO:</strong>  {data.cliente.cidade} <br />
                                    <strong>TELEFONES:</strong>  {data.cliente.fone} | {data.cliente.celular} | {data.cliente.telefone} <br />
                                    <strong>FORMA PAGTO:</strong> {data.forma_pagamento && data.forma_pagamento.name} - <strong>TIPO DE PAGTO: </strong> {data.tipo_pagamento && data.tipo_pagamento.descricao}
                                    <br /><strong>VENDEDOR:</strong> {data.vendedor.name} <br />
                                    <strong>OBS: </strong> {data.cliente.observacao} <br />
                                    <strong>PESO TOTAL: </strong> {data.peso_total}  <br />
                                    <hr />
                                    
                                    <Table striped hover style={{ border: '1px solid #CCC', width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>PRODUTO</th>
                                                <th>QTDE</th>
                                                <th>R$ UNIT.</th>
                                                <th>R$ SUBTOTAL</th>
                                                <th>R$ DESC.</th>
                                                <th>R$ TOTAL C/ DESC.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.itens.map((item, index) => (
                                                <tr>
                                                    <td>{item.produto.titulo}</td>
                                                    <td>{item.quantidade}</td>
                                                    <td>{Helpers.converterFloatReal(item.preco)}</td>
                                                    <td>{Helpers.converterFloatReal(item.preco * item.quantidade)}</td>
                                                    <td>{Helpers.converterFloatReal(parseFloat(item.preco * item.quantidade) * parseFloat(data.desconto) / 100)}</td>
                                                    <td>
                                                        {Helpers.converterFloatReal(parseFloat(item.preco * item.quantidade) - (parseFloat(item.preco * item.quantidade) * parseFloat(data.desconto) / 100))}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col md={6}>
                                    Declaro que estou de pleno acordo com o pedido.<br /><br />
                                    ___________________________________ <br />
                                    {data.cliente.razao_social}
                                </Col>
                                <Col>
                                    <Table striped hover>
                                        <tbody>
                                            <tr>
                                                <td>VALOR PRODUTO</td>
                                                <td>
                                                    <strong>
                                                        {Helpers.converterFloatReal(data.total)}
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>(-) DESCONTO</td>
                                                <td>
                                                    <strong>
                                                        {Helpers.converterFloatReal(parseFloat(data.total) * parseFloat(data.desconto) / 100)}
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>VALOR A PAGAR</th>
                                                <td>
                                                    <strong>
                                                        {Helpers.converterFloatReal(parseFloat(data.total) - (parseFloat(data.total) * parseFloat(data.desconto) / 100))}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Pedido conferido e recebido: Data: ___/___/______. <br /><br />
                                    Ass: __________________________________________________ <br />
                                    {data.cliente.razao_social}
                                </Col>
                            </Row>

                        </Container>
                    } */}
            </Modal.Body>
            </Modal>
        </>
        
        
    );
}