import React from 'react';
import {
    BrowserRouter as Router,
    Switch
  } from "react-router-dom";
import { AuthProvider } from 'src/context/auth';
import RouterPrivate from './RouterPrivate';

//   Pages
import Home from 'src/pages/Home';
import Login from 'src/pages/Login';
import Users from 'src/pages/Users';
import Clients from 'src/pages/Cadastros/Clients';
import Fornecedores from 'src/pages/Cadastros/Fornecedores';
import Cargos from 'src/pages/Cadastros/Cargos';
import TipoDespesa from 'src/pages/Cadastros/TipoDespesa';
import Colaborador from 'src/pages/Cadastros/Colaborador';
import TablePrices from 'src/pages/Cadastros/TablePrices';
import FormaPagamento from 'src/pages/Cadastros/FormaPagamento';
import Produtos from 'src/pages/Cadastros/Produtos';
import ClientsVendedor from 'src/pages/PainelVendedor/ClientsVendedor';
import Orders from 'src/pages/Orders';
import Pedidos from 'src/pages/Relatorios/Pedidos';
import Vendas from 'src/pages/Relatorios/Vendas';
import { HelperProvider } from 'src/context/helper';
import Agrupados from 'src/pages/Relatorios/Agrupados';
import PedidoView from 'src/pages/PedidoView';
import Clientes from 'src/pages/Relatorios/Clientes';
import Comissoes from 'src/pages/Financeiro/Comissoes';
import PedidosRomaneio from 'src/pages/Relatorios/PedidosRomaneio';
import UltimasVendas from 'src/pages/Relatorios/UltimasVendas';
import ProdutosMaisVendidos from 'src/pages/Relatorios/ProdutosMaisVendidos';
import LancamentosPedidos from 'src/pages/Producao/LancamentosPedidos';
import Status from 'src/pages/Producao/Status';
import LancarProduzidos from 'src/pages/Producao/LancarProduzidos';
import Visitas from 'src/pages/Relatorios/Visitas';
import OrdemProducao from 'src/pages/Producao/OrdemProducao';
import LancarComissoes from 'src/pages/Producao/LancarComissoes';
import EstoqueProducao from 'src/pages/Producao/EstoqueProducao';
import ComissoesProducoes from 'src/pages/Financeiro/ComissoesProducoes';
import Cores from 'src/pages/Cadastros/Cores';
import EstoquePedido from 'src/pages/Producao/EstoquePedido';
import Permissoes from 'src/pages/Permissoes';

export default function MainRouter() {   

    const routes = [
        {
            path: '/login',
            component: Login,
            isPrivate: false,
        },
        {
            path: '/',
            component: Home,
            exact: true,
            isPrivate: true,
        },
        {
            path: '/clients',
            component: Clients,
            isPrivate: true,
        },
        {
            path: '/tipo-despesa',
            component: TipoDespesa,
            isPrivate: true,
        },
        {
            path: '/forma-pagamento',
            component: FormaPagamento,
            isPrivate: true,
        },
        {
            path: '/fornecedores',
            component: Fornecedores,
            isPrivate: true,
        },
        {
            path: '/produtos',
            component: Produtos,
            isPrivate: true,
        },
        {
            path: '/cargos',
            component: Cargos,
            isPrivate: true,
        },
        {
            path: '/cores',
            component: Cores,
            isPrivate: true,
        },
        {
            path: '/colaborador',
            component: Colaborador,
            isPrivate: true,
        },
        {
            path: '/lancar-produzidos',
            component: LancarProduzidos,
            isPrivate: true
        },
        {
            path: '/ordens-producoes',
            component: OrdemProducao,
            isPrivate: true
        },
        {
            path: '/status-producao',
            component: Status,
            isPrivate: true,
        },
        {
            path: '/configurar-comissoes',
            component: LancarComissoes,
            isPrivate: true,
        },
        {
            path: '/table-prices',
            component: TablePrices,
            isPrivate: true,
        },
    
        {
            path: '/lancar-producao',
            component: LancamentosPedidos,
            isPrivate: true,
        },
        {
            path: '/estoque-producao',
            component: EstoqueProducao,
            isPrivate: true,
        },
        {
            path: '/estoque-pedido',
            component: EstoquePedido,
            isPrivate: true,
        },
        {
            path: '/orders',
            component: Orders,
            isPrivate: true,
        },
        {
            path: '/relatorio-pedido',
            component: Pedidos,
            isPrivate: true,
        },
        {
            path: '/relatorio-agrupados',
            component: Agrupados,
            isPrivate: true,
        },
        {
            path: '/relatorio-vendas',
            component: Vendas,
            isPrivate: true,
        },
        {
            path: '/ultimas-vendas',
            component: UltimasVendas,
            isPrivate: true,
        },
        {
            path: '/produtos-mais-vendidos',
            component: ProdutosMaisVendidos,
            isPrivate: true,
        },
        
        {
            path: '/relatorio-romaneio',
            component: PedidosRomaneio,
            isPrivate: true,
        },
        
        {
            path: '/relatorio-comissoes',
            component: Comissoes,
            isPrivate: true,
        },
        {
            path: '/relatorio-producoes',
            component: ComissoesProducoes,
            isPrivate: true,
        },
        
        {
            path: '/relatorio-clientes',
            component: Clientes,
            isPrivate: true,
        },
        {
            path: '/relatorio-visitas',
            component: Visitas,
            isPrivate: true,
        },
        {
            path: '/pedido/:id',
            component: PedidoView,
            isPrivate: false,
            isLayout: false
        },
        {
            path: '/users',
            component: Users,
            isPrivate: true,
        },
        {
            path: '/permissoes',
            component: Permissoes,
            isPrivate: true,
        }
        // VENDENDOR
        ,
        {
            path: '/clients-vendedor',
            component: ClientsVendedor,
            isPrivate: true,
        },
        {
            path: '/pedido-vendedor',
            component: ClientsVendedor,
            isPrivate: true,
        },
        {
            path: '/produto-vendedor',
            component: ClientsVendedor,
            isPrivate: true,
        },
        {
            path: '/perfil',
            component: ClientsVendedor,
            isPrivate: true,
        },
    
    ];
    
    return (
        <>
            <AuthProvider values={{ isLogged: false }}>
                <HelperProvider values={{ }}>
                    <Router>
                        <Switch>
                            {routes.map((route, idx) => (
                                <RouterPrivate key={idx} {...route} />
                            ))}
                        </Switch>
                    </Router>
                </HelperProvider>
           </AuthProvider>
        </>
    );
}

