import React, { useContext } from 'react';
import AuthContext from 'src/context/auth';
import Navbar from './Navbar';

export default function TopBar() {
    const { handleLogout, user } = useContext(AuthContext);
    
    async function getToggleMenu() {
        if(document.getElementById("body").classList.contains('nav-sm')) {
            document.getElementById("body").className = "nav-md";
        } else {
            document.getElementById("body").className = "nav-sm";
        }
    }

    return (
        <div className="top_nav">
            <div className="nav_menu">
                <div className="nav toggle">
                <a  href id="menu_toggle" onClick={() => getToggleMenu()}><i className="fa fa-bars"></i></a>
                    
                </div>
                <nav className="nav navbar-nav">
                    <ul className=" navbar-right">
                        <li className="nav-item dropdown open" style={{paddingLeft: '15px'}}>
                        <a href className="user-profile dropdown-toggle" aria-haspopup="true" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
                        <img src="https://estudandoabiblia.org/wp-content/uploads/2020/04/pngflow.com_.png" alt="" />{user?.name || ''}
                        </a>
                        <div className="dropdown-menu dropdown-usermenu pull-right" aria-labelledby="navbarDropdown">
                        <a href className="dropdown-item"  > Perfil</a>
                            <a href className="dropdown-item"  >
                            <span className="badge bg-red pull-right">50%</span>
                            <span>Configurações</span>
                            </a>
                        <a className="dropdown-item"  href>Ajuda</a>
                        <a className="dropdown-item" onClick={handleLogout} href><i className="fa fa-sign-out pull-right"></i> Sair</a>
                        </div>
                    </li>

                        <li role="presentation" className="nav-item dropdown open">
                        <a href className="dropdown-toggle info-number" id="navbarDropdown1" data-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-envelope-o"></i>
                        <span className="badge bg-green">6</span>
                        </a>
                        <ul className="dropdown-menu list-unstyled msg_list" role="menu" aria-labelledby="navbarDropdown1">
                        <li className="nav-item">
                            <a className="dropdown-item">
                            <span className="image"><img src="images/img.jpg" alt="Profile Image" /></span>
                            <span>
                                <span>John Smith</span>
                                <span className="time">3 mins ago</span>
                            </span>
                            <span className="message">
                                Film festivals used to be do-or-die moments for movie makers. They were where...
                            </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="dropdown-item">
                            <span className="image"><img src="images/img.jpg" alt="Profile Image" /></span>
                            <span>
                                <span>John Smith</span>
                                <span className="time">3 mins ago</span>
                            </span>
                            <span className="message">
                                Film festivals used to be do-or-die moments for movie makers. They were where...
                            </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="dropdown-item">
                            <span className="image"><img src="images/img.jpg" alt="Profile Image" /></span>
                            <span>
                                <span>John Smith</span>
                                <span className="time">3 mins ago</span>
                            </span>
                            <span className="message">
                                Film festivals used to be do-or-die moments for movie makers. They were where...
                            </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="dropdown-item">
                            <span className="image"><img src="images/img.jpg" alt="Profile Image" /></span>
                            <span>
                                <span>John Smith</span>
                                <span className="time">3 mins ago</span>
                            </span>
                            <span className="message">
                                Film festivals used to be do-or-die moments for movie makers. They were where...
                            </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <div className="text-center">
                            <a className="dropdown-item">
                                <strong>See All Alerts</strong>
                                <i className="fa fa-angle-right"></i>
                            </a>
                            </div>
                        </li>
                        </ul>
                    </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}