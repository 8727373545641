import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function FilterGlobal(props) {
  return (
    <>
        <Row>
            {props.isEnableFieldStatus && 
                <Col md={3}>
                    <div className="form-group input-group-sm ">
                        <label>Status</label>
                        <select 
                            name="status" 
                            className="form-control"
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                        >
                            <option value="">Selecione</option>
                            <option value='open'>Em Aberto</option>
                            <option value='waiting'>Aguardando</option>
                            <option value='pending'>Pendente</option>
                            <option value='approved'>Aprovado</option>
                            <option value='production'>Em Produção</option>
                            <option value='travel'>Em Viagem</option>
                            <option value='finished'>Finalizado</option>
                            <option value='cancel'>Cancelado</option>
                        </select>
                    </div>
                </Col>
            }
            {props.isEnableFieldData && 
                <>
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Data Inicial</label>
                        <input 
                            value={props.params.data_inicio} 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            className="form-control" 
                            type="date" 
                            name="data_inicio" />
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Data Final</label>
                        <input 
                            value={props.params.data_fim}  
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})}
                            className="form-control" 
                            type="date" 
                            name="data_fim" />
                    </div>
                </Col>
                </>
            }
            {props.isEnableFieldSearch && 
                <Col>
                    <div className="form-group input-group-sm ">
                        <label>Buscar por Cliente / CNPJ / CPF / Endereço / Vendedor </label>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="search" 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} 
                        />
                    </div>
                </Col>
            }
            <Col>
                <div className="form-group input-group-sm ">
                    <label>CNPJ</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="cnpj" 
                        value={props.params.cnpj}
                        onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} 
                    />
                </div>
            </Col>
        </Row>
        
        <Row>
            <Col>
                <div className="form-group input-group-sm ">
                    <label>Bairro</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        name="bairro" 
                        onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} />
                </div>
            </Col>
            <Col>
                <div className="form-group input-group-sm ">
                    <label>Cidade</label>
                    <input type="text" className="form-control" name="cidade" onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} />
                </div>
            </Col>
            <Col>
                <div className="form-group input-group-sm ">
                    <label>Estado</label>
                    <input type="text" className="form-control" name="estado" onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} />
                </div>
            </Col>
            <Col>
                <div className="form-group input-group-sm ">
                    <label>Vendedor</label>
                    <input type="text" className="form-control" name="vendedor" onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} />
                </div>
            </Col>
        </Row>
        
        <Row>
            {props.isEnableFieldCodigo &&
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Código do Pedido</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="codigo" 
                            onChange={e => props.setParams({...props.params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
            }
            {props.isEnableFieldAllPedidos && 
                <Col>
                    <div className="form-group">
                        <br />
                        <label id="all_pedidos">
                            <input 
                                id="all_pedidos"
                                checked={props.params.all_clientes}
                                type="checkbox"
                                name="all_pedidos"
                                onChange={e => {
                                    if(e.target.checked) {
                                        let lis = props.list.map(item => item.id);
                                        props.setSelected([...lis]);
                                    } else {
                                        props.setSelected([]);
                                    }
                                    props.setParams({...props.params, ...{ [e.target.name]: e.target.checked }});
                                }}
                            />
                            &nbsp;
                            {props.titleCheckAll}
                        </label>
                    </div>
                </Col>
            }
            {props.isEnableFieldAscDesc && 
                <Col>
                    <div className="form-group">
                        <br />
                        <label id="ordem">
                            <input 
                                id="ordem"
                                checked={props.params.ordem}
                                type="checkbox"
                                name="ordem"
                                onChange={e => {
                                    props.setParams({...props.params, ...{ [e.target.name]: e.target.checked }});
                                }}
                            />
                            &nbsp;
                            Decrescente
                        </label>
                    </div>
                </Col>
            }
        </Row>
    </>
  );
}

FilterGlobal.defaultProps = {
    params: {
        data_inicio: '',
        data_fim: '',
        cnpj: '',
        all_pedidos: false
    },
    titleCheckAll: 'Todos os Pedidos?',
    isEnableFieldStatus: true,
    isEnableFieldData: true,
    isEnableFieldSearch: false,
    isEnableFieldCodigo: true,
    isEnableFieldAllPedidos: true,
    isEnableFieldAscDesc: false,
};