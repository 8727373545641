import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import Pagination from 'src/components/Pagination';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import ReportPdf from 'src/components/ReportPdf';
import Message from 'src/core/Message';
import { ImInsertTemplate } from 'react-icons/im';

export default function Visitas() {

    const [dataIni, setDataIni] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [page, setPage] = useState(0);
    const [visitas, setVisitas] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({
        has_tabela: true,
        all_clientes: false,
    });
  
    async function getClientes() {
        const response = await Api('get', 'visitas', '', {
            params: {
                page: page,
                ...params
            }
        });        
        setVisitas(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    async function agrupar() {
        if(!selected.length) {
            Message.infor("Selecione pelo menos um pedido.");
            return false;
        }
        setShowPdf(true);
    }

    useEffect(() => {
        getClientes();
    }, [page, params]);

    useEffect(() => {
        if(!dataIni && !dataFim) {
            // getTotais();
        }
    }, [dataIni, dataFim]);

    return (
        <div className="" role="main">
            <ReportPdf 
                title="Visitas"
                endPoint="visitas"
                params={{
                    visitas: selected,
                    ...params
                }}
                show={showPdf}
                setShow={setShowPdf}
            />

            <h4>Visitas</h4>

            <Row>
                <Col>
                    <Button style={{ float: 'right' }} size="sm" variant="success" onClick={() => agrupar()}>Gerar PDF</Button>
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Data Inicial</label>
                        <input 
                            value={params.data_inicio} 
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            className="form-control" 
                            type="date" 
                            name="data_inicio" />
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Data Final</label>
                        <input 
                            value={params.data_fim}  
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            className="form-control" 
                            type="date" 
                            name="data_fim" />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="form-group">
                        <label>Situação</label>
                        <select 
                            name="situacao" 
                            className="form-control"
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                        >
                            <option value="">Selecione</option>
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Bairro</label>
                        <input type="text" className="form-control" name="bairro" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Cidade</label>
                        <input type="text" className="form-control" name="cidade" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Estado</label>
                        <input type="text" className="form-control" name="estado" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Vendedor</label>
                        <input type="text" className="form-control" name="vendedor" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="form-group">
                        <label id="asc">
                            <input 
                                id="asc"
                                checked={params.asc}
                                type="checkbox"
                                name="asc"
                                onChange={e => setParams({...params, ...{ [e.target.name]: e.target.checked }})}
                            />
                            &nbsp;
                            Decrescente
                        </label>
                    </div>

                </Col>
                <Col>
                    <div className="form-group">
                        <label id="all_visitas">
                            <input 
                                id="all_visitas"
                                checked={params.all_clientes}
                                type="checkbox"
                                name="all_visitas"
                                onChange={e => {
                                    if(e.target.checked) {
                                        let lis = visitas.map(item => item.id);
                                        setSelected([...lis]);
                                    } else {
                                        setSelected([]);
                                    }
                                    setParams({...params, ...{ [e.target.name]: e.target.checked }});
                                }}
                            />
                            &nbsp;
                            Todas as Visitas?
                        </label>
                    </div>

                </Col>
            </Row>

            {!visitas.length && 'Nenhum resultado'}
            <Table className={!visitas.length && 'none'}>
                    <tr>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Cliente</th>
                        <th>Observação</th>
                        <th>Localização</th>
                    </tr>
                    {visitas.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td>{item.data_visita_f}</td>
                            <td>{item.hora_check_in ? item.hora_check_in : item.hora_visita}</td>
                            <td>
                                <strong>Razão Social: </strong> {item.cliente.razao_social} <br />
                                {/* <strong>Bairro: </strong> {item.cliente.bairro} - <strong>Cidade: </strong> {item.cliente.cidade} - <strong>Estado: </strong> {item.cliente.uf} <br />
                                <strong>CNPJ/CPF: </strong>{item.cliente.cnpj ? item.cliente.cnpj : item.cliente.cpf} <br /> */}
                                <strong>Vendedor: </strong>{item.vendedor.name}
                            </td>
                            <td>
                                {item.observacao}
                            </td>
                            <td>
                                {item.lat_check_in}
                                <br />
                                {item.lng_check_in}
                            </td>
                        </tr>
                    ))}
            </Table>

            <hr />
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
        </div>
    );
}
