import React from 'react';

export default function Footer() {   
    return (
        <footer>
            <div className="pull-right">
                Parlumin <a href="https://colorlib.com">@</a>
            </div>
            <div className="clearfix"></div>
        </footer>
    );
}

