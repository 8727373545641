import MainRouter from "./routes/MainRouter";
import LoadingScreen from 'src/components/LoadingScreen';
import './index.css';
import PaperOrder from "./components/PaperOrder";
function App() {
  return (
    <>
      {/* <PaperOrder pedido_id={2} /> */}
      <LoadingScreen />
      <MainRouter />
    </>
  );
}

export default App;
