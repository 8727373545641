import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Table } from 'react-bootstrap';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import Form from '../Form';
import { Select, InputText, InputTextarea } from '../FormHelper/Input';

const FormWrapper = ({ data, values, Field, ErrorMessage }) => {

    const [listFormasPagamentos, setListFormasPagamentos] = useState([]);
    const [listTiposPagamentos, setListTiposPagamentos] = useState([]);

    async function getFormasPagamentos() {
        const response = await Api('get', 'forma-pagamento/options', '', {});        
        setListFormasPagamentos(response.data);
    }

    async function getTiposPagamentos() {
        const response = await Api('get', 'itens-tipos-pagamentos/options', '', {
            params: {
                forma_pagamento_id: values.forma_pagamento_id
            }
        });        
        setListTiposPagamentos(response.data);
    }

    useEffect(() => {
        getFormasPagamentos();
    }, []);

    useEffect(() => {
        if(values.forma_pagamento_id) {
            getTiposPagamentos();
        }
    }, [values.forma_pagamento_id])

    return (
        <>
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="forma_pagamento_id"
                        label="Forma de Pagamento"
                        name="forma_pagamento_id"
                        component={Select}
                        placeholder="Selecione a Forma de Pagamento"
                        list={listFormasPagamentos}
                        error={<ErrorMessage name="forma_pagamento_id" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="item_tipo_pagamento_id"
                        label="Tipo de Pagamento"
                        name="item_tipo_pagamento_id"
                        placeholder="Selecione o Tipo de Pagamento"
                        component={Select}
                        list={listTiposPagamentos}
                        error={<ErrorMessage name="item_tipo_pagamento_id" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="desconto"
                        label="Desconto %"
                        name="desconto"
                        component={InputText}
                        list={listTiposPagamentos}
                        error={<ErrorMessage name="desconto" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        id="frete"
                        label="Frete"
                        name="frete"
                        component={Select}
                        list={[
                            { label: 'CIF', value: 'CIF' },
                            { label: 'FOB', value: 'FOB' },
                        ]}
                        placeholder="Selecione o Frete"
                        error={<ErrorMessage name="frete" />}
                    />
                </Col>
                <Col md={12}>
                    <Field
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        component={InputTextarea}
                        error={<ErrorMessage name="observacao" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table hover striped>
                        <tbody>
                            <tr>
                                <td>Total</td>
                                <td style={{  textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(data.total)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Desconto</td>
                                <td style={{  textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(parseFloat(data.total) * parseFloat(values.desconto) / 100)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '16px' }}>
                                    <strong>
                                        Total com Desconto
                                    </strong>
                                </td>
                                <td style={{ fontSize: '16px', textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(parseFloat(data.total) - (parseFloat(data.total) * parseFloat(values.desconto) / 100))}
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

export default function FinishOrder({ pedido_id, showFinish, setShowFinish, handleEdit }) {

    const [data, setData] = useState(null);

    async function handleSubmit(values) {
        await Api('put', `pedidos/${pedido_id}`, '', {
            id: pedido_id,
            forma_pagamento_id: values.forma_pagamento_id,
            item_tipo_pagamento_id: values.item_tipo_pagamento_id,
            desconto: values.desconto,
            observacao_status: values.observacao,
            frete: values.frete,
        });        
        Message.success("Pedido Finalizado com Sucesso");
        handleEdit({ id: pedido_id });
        setShowFinish(false);
    }

    async function getData() {
        if(pedido_id) {
            const response = await Api('get', `pedidos/${pedido_id}`, '', {});        
            setData(response.data);
        }       
    }

    useEffect(() => {
        getData();
    }, [pedido_id]);

    return (
        <>
            <Modal
                show={showFinish}
                onHide={() => setShowFinish(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Finalizar Pedido
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form 
                        data={data}
                        endPoint='pedidos'
                        emptyObject={data}
                        enableBtnCancel={false}
                        validation={(yup) => {
                            return {
                                forma_pagamento_id: yup.string().required("Campo obrigatório").nullable(),
                                item_tipo_pagamento_id: yup.string().required("Campo obrigatório").nullable(),
                                desconto: yup.string().required("Campo obrigatório"),
                            };
                        }}
                        handleSubmit={handleSubmit}
                        FormWrapper={FormWrapper}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}