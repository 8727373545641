import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Tooltips({ text, children }) {
    return (
        <>
            <OverlayTrigger
                key={'top'}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        {text}
                    </Tooltip>
                }
                >
                {children}
            </OverlayTrigger>
        </>
    );
}