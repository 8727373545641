import React from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { AuthorizationMenuListar } from 'src/layouts/Menu';

export default function FormButtons(props) {
    return (
        <FormGroup style={{ marginTop: '10px', float: 'right' }}>
            
            <AuthorizationMenuListar action={props.endPoint} btnAction="novo">
                {props.enableBtnSave && props.view === 'new' &&
                    <Button size="sm" variant="success" onClick={props.handleSave}>{props.titleBtnSave}</Button>
                }
            </AuthorizationMenuListar>

            <AuthorizationMenuListar action={props.endPoint} btnAction="editar">
                {props.enableBtnSave && props.view === 'edit' &&
                    <Button size="sm" variant="success" onClick={props.handleSave}>{props.titleBtnSave}</Button>
                }
            </AuthorizationMenuListar>

            {props.enableBtnSave && (props.view !== 'edit' && props.view !== 'new') &&
                <Button size="sm" variant="success" onClick={props.handleSave}>{props.titleBtnSave}</Button>
            }
            
            
            &nbsp;
            {props.enableBtnCancel && 
                <Button size="sm" variant="warning" onClick={props.handleCancel}>{props.titleBtnCancel}</Button>
            }
        </FormGroup>
    );
}

FormButtons.defaultProps = {
    enableBtnSave: true,
    enableBtnCancel: true,
    titleBtnSave: 'Salvar',
    titleBtnCancel: 'Cancelar'
};