import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import Api from 'src/core/api';
import Pagination from 'src/components/Pagination';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import ReportPdf from 'src/components/ReportPdf';
import Util from 'src/core/Util';

export default function Vendas() {

    const [dataIni, setDataIni] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
        
    const [pagination, setPagination] = useState({});  
    const [show, setShow] = useState(false);

    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({
        has_tabela: true
    });
    
  
    async function getPedidos() {
        // - APROVADO
        // - EM PRODUCAO
        // - EM VIAGEM
        // - FINALIZADO

        const response = await Api('get', 'pedidos', '', {
            params: {
                page: page,
                status_list: ["approved", "production", "travel", "finished"],
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    useEffect(() => {
        getPedidos();
    }, [page, params]);


    return (
        <div className="" role="main">
            <ReportPdf 
                title="Vendas"
                endPoint="vendas"
                params={{
                    pedidos: selected,
                    status_list: ["approved", "production", "travel", "finished"],
                    ...params,
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            <h4>Vendas</h4>
            
            <Row>
                <Col>
                    <Button style={{ float: 'right' }} size="sm" variant="success" onClick={() => setShowPdf(true)}>Gerar PDF</Button>
                </Col>
            </Row>
            
            <Row>
                <Col md={3}>
                    <div className="form-group input-group-sm ">
                        <label>Status</label>
                        <select 
                            name="status" 
                            className="form-control"
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                        >
                            <option value="">Selecione</option>
                            <option value='open'>Em Aberto</option>
                            <option value='waiting'>Aguardando</option>
                            <option value='pending'>Pendente</option>
                            <option value='approved'>Aprovado</option>
                            <option value='production'>Em Produção</option>
                            <option value='travel'>Em Viagem</option>
                            <option value='finished'>Finalizado</option>
                            <option value='cancel'>Cancelado</option>
                        </select>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Data Inicial</label>
                        <input value={dataIni} 
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            className="form-control" 
                            type="date" 
                            name="data_inicio" />
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-group input-group-sm ">
                        <label>Data Final</label>
                        <input 
                            value={dataFim}  
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                            className="form-control" 
                            type="date" 
                            name="data_fim" />
                    </div>
                </Col>
                <Col>
                    <div className="form-group input-group-sm ">
                        <label>CNPJ</label>
                        <input type="text" className="form-control" name="cnpj" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group input-group-sm ">
                        <label>Bairro</label>
                        <input type="text" className="form-control" name="bairro" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group input-group-sm ">
                        <label>Cidade</label>
                        <input type="text" className="form-control" name="cidade" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group input-group-sm ">
                        <label>Estado</label>
                        <input type="text" className="form-control" name="estado" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group input-group-sm ">
                        <label>Vendedor</label>
                        <input type="text" className="form-control" name="vendedor" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <br />
                        <label id="all_pedidos">
                            <input 
                                id="all_pedidos"
                                checked={params.all_clientes}
                                type="checkbox"
                                name="all_pedidos"
                                onChange={e => {
                                    if(e.target.checked) {
                                        let lis = pedidos.map(item => item.id);
                                        setSelected([...lis]);
                                    } else {
                                        setSelected([]);
                                    }
                                    setParams({...params, ...{ [e.target.name]: e.target.checked }});
                                }}
                            />
                            &nbsp;
                            Todos os Pedidos?
                        </label>
                    </div>
                </Col>
            </Row>
            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                    <tr>
                        <th>Código</th>
                        <th>Status</th>
                        <th>Cliente</th>
                        <th>Data do Pedido</th>
                        <th>Valor Total</th>
                    </tr>
                    {pedidos.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td style={{ width: '25px' }}>{item.codigo}</td>
                            <td style={{ width: '25px' }}>
                                {Util.getStatusSpan(item.status)}
                            </td>
                            <td>
                                <strong>Razão Social: </strong> {item.cliente.razao_social} <br />
                                <strong>CNPJ/CPF: </strong> {item.cliente.cnpj ? item.cliente.cnpj : item.cliente.cpf} <br />
                                <strong>Bairro: </strong> {item.cliente.bairro} - <strong>Cidade: </strong> {item.cliente.cidade} - <strong>Estado: </strong> {item.cliente.uf} 
                                <br />
                                <strong>Vendedor: </strong> {item.vendedor.name} <br />
                            </td>
                            
                            <td>{item.data}</td>
                            
                            <td>{Helpers.converterFloatReal(item.total)}</td>
                            
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Pedidos Agrupados
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tr>
                            <th>Descrição</th>
                            <th  style={{ textAlign: 'right' }}>Quantidade</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                        {produtos.map((item, index) => (
                            <tr>
                                <td>{item.produto.titulo}</td>
                                <td style={{ textAlign: 'right' }}>{item.quantidade}</td>
                                <td style={{ textAlign: 'right' }}>{Helpers.converterFloatReal(item.valor)}</td>
                            </tr>
                        ))}
                        <tr>
                            <th>Total</th>
                            
                            <th colSpan="2" style={{ textAlign: 'right' }}>{Helpers.converterFloatReal(produtos.length > 0 && produtos[0].total)}</th>
                        </tr>
                    </Table>
                </Modal.Body>
            </Modal>
            
           
            <hr />
        </div>
    );
}
