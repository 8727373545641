import { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap";

export default function FormController(props) {

    const[list, setList] = useState([
        'listar',
        'novo',
        'editar',
        'excluir',
    ]);

    function handleCheck(e) {
        let controllerObj = props.data[props.controller];
        controllerObj[e.target.name] = e.target.checked;
        props.setData({...props.data, controllerObj  })
        
    }

    function handleCheckAll(e) {
        let controllerObj = props.data[props.controller];
        Object.keys(controllerObj).forEach(key => controllerObj[key] = e.target.checked);
        console.log(controllerObj);
        props.setData({...props.data, controllerObj  })
    }

    useEffect(() => {
        if(props.list.length) {
            setList([...list, ...props.list]);
        }
    }, [props.list]);

    return (
        <>
        <Row>
            <Col md={3}>
                <strong>
                <label>
                    <input
                        name={props.controller}
                        type="checkbox" 
                        onChange={e => handleCheckAll(e)}
                    />
                    &nbsp;
                    {props.nome}
                </label>
                </strong>
                
            </Col>
            
            {list.map(item => (
                <Col>
                    {/* {props.data[props.controller][item] ? 'True' : 'False'} */}
                    <label>
                        <input
                            name={item}
                            checked={props.data[props.controller] && props.data[props.controller][item] ? true : false}
                            type="checkbox" 
                            onChange={e => handleCheck(e)}
                        />
                        &nbsp;
                        {item.toUpperCase()}
                    </label>
                </Col>
            ))}
        </Row>
        <hr />
        </>
    )
}

FormController.defaultProps = {
    nome: '',
    list: []
}

