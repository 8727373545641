import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import {  InputText, InputTextarea, Select } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
// import Api from 'src/core/api';
import Api from 'src/core/api';

const FormWrapper = ({ Field, ErrorMessage, setFieldValue, values, view }) => {

    const [colaborador, setColaborador] = useState([]);
    const [status, setStatus] = useState([]);
    const [cores, setCores] = useState([]);
    const [filterSemiProduto, setFilterSemiProduto] = useState('semi_produto');

    async function getColaborador() {
        const response = await Api('get', 'colaborador/options', '', {
            params: {
                setor: 'producao'
            }
        });        
        setColaborador(response.data);
    }

    async function getCores() {
        const response = await Api('get', `cores/options`, '', {});        
        setCores(response.data);
    }

    async function getStatus() {
        const response = await Api('get', 'status-producao/options', '', {});        
        setStatus(response.data);
    }

    useEffect(() => {
        
        // const indiceStatus = status.findIndex(item => item.value == values.status_producao_id && item.is_final == 1);
        // console.log(indiceStatus);
        // if(indiceStatus >= 1) {
        //     setFilterSemiProduto('produto');
        // } else {
        //     setFilterSemiProduto('semi_produto');
        // }
        // console.log(indiceStatus)
        const checkList = status.filter(item => {
            if(item.value == values.status_producao_id && item.is_final == 1) {
               return item
            }
        })
        if(checkList.length > 0) {
            setFilterSemiProduto('produto');
        } else {
            setFilterSemiProduto('semi_produto');
        }
    }, [values.status_producao_id]);

    useEffect(() => {
        getColaborador();
        getStatus();
        getCores();
    }, []);

    const isDisabled = values.id ? true : false;
  

    return (
        <>
            <Row>
                <Col md={12}>
                    <Field 
                        required
                        disabled={isDisabled}
                        id="colaborador_id"
                        label="Colaborador"
                        name="colaborador_id"
                        placeholder="Selecione"
                        component={Select}
                        list={colaborador}
                        error={<ErrorMessage name="colaborador_id" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Field 
                        disabled={isDisabled}
                        id="aux_colaborador_id"
                        label="Auxiliar"
                        name="aux_colaborador_id"
                        placeholder="Selecione"
                        component={Select}
                        list={colaborador}
                        error={<ErrorMessage name="aux_colaborador_id" />}
                    />
                </Col>
            </Row>    
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        disabled={isDisabled}
                        id="status_producao_id"
                        label="Status de Produção"
                        name="status_producao_id"
                        placeholder="Selecione"
                        component={Select}
                        list={status}
                        error={<ErrorMessage name="status_producao_id" />}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={9}>
                    {view === 'new' ?
                        <>
                            <label>Selecione alerto Produto</label>
                            
                            <Field 
                                required
                                disabled={isDisabled}
                                id="produto_id"
                                label="Produto"
                                name="produto_id"
                                placeholder="Selecione"
                                endPoint="produtos/options"
                                component={SelectAutoComplete}
                                params={{
                                    tipo: filterSemiProduto,
                                    update:  filterSemiProduto,
                                }}
                                error={<ErrorMessage name="produto_id" />}
                            />
                        </>
                    : 
                        <>
                            <hr />
                            <h6>Produto: </h6>
                            <strong>Título: </strong> {values.produto.titulo} <br />
                            <strong>Descrição: <br /> </strong> {values.produto.descricao} <br />
                            <hr />
                        </>
                    }
                    
                </Col>
                
                <Col md={3}>
                    <Field 
                        required
                        id="cor_id"
                        label="Cor"
                        name="cor_id"
                        component={Select}
                        list={cores}
                        error={<ErrorMessage name="cor_id" />}
                    />
                </Col>
            </Row> 
            <Row>
                
                <Col md={4}>
                    <Field 
                        required
                        disabled={isDisabled}
                        id="quantidade"
                        label="Quantidade"
                        name="quantidade"
                        component={InputText}
                        error={<ErrorMessage name="quantidade" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        readonly
                        disabled={isDisabled}
                        id="data_lancamento"
                        label="Data de Lançamento"
                        name="data_lancamento"
                        component={InputText}
                        type="date"
                        error={<ErrorMessage name="data_lancamento" />}
                    />
                </Col>
            </Row> 

            <Row>
                <Col md={12}>
                    <Field 
                        disabled={isDisabled}
                        id="observacao"
                        label="Observação"
                        name="observacao"
                        component={InputTextarea}
                        error={<ErrorMessage name="observacao" />}
                    />
                </Col>
            </Row> 
        </>
    );
}

export default function LancarProduzidos() {
    return (
        <Crud 
            title="Lançar Produzidos"
            endPoint="lancar-produzidos"
            searchFieldName='search'
            emptyObject={{
                colaborador_id: '',
                aux_colaborador_id: '',
                status_producao_id: '',
                produto_id: '',
                quantidade: '',
                data_lancamento: '',
                observacao: ''
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'colaborador', label: 'Colaborador' },
                { name: 'produto', label: 'Produto' },
                { name: 'quantidade', label: 'Quantidade' },
                { name: 'status', label: 'Status' }
            ]}
            fieldsHtml={({ item }) => (
                <>
                    <td>{item.id}</td>
                    <td>{item.colaborador.name}</td>
                    <td>{item.produto.titulo}</td>
                    <td>{item.quantidade}</td>
                    <td>{item.status_producao.nome}</td>
                </>
            )}
            validation={(yup) => {
                return {
                    colaborador_id: yup.string().required("Campo obrigatório"),
                    status_producao_id: yup.string().required("Campo obrigatório"),
                    produto_id: yup.string().required("Campo obrigatório"),
                    quantidade: yup.string().required("Campo obrigatório"),
                    data_lancamento: yup.date('Data Inválida').required("Campo obrigatório")
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
