import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Api from 'src/core/api';
import Message from 'src/core/Message';

const FormWrapper = ({ Field, ErrorMessage }) => {
   
    return (
        <>
            <Row>
                <Col md={3}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
            </Row>  
            <Row>
                <Col>
                    <label>Selecione o Produto</label>
                    <Field
                        label="Produtos"
                        name="produto_id"
                        id="produto_id"
                        enableFieldForm={true}
                        disabled
                        endPoint="produtos/options"
                        component={SelectAutoComplete}
                        error={<ErrorMessage name="produto_id" />}
                    />
                </Col>
            </Row> 
            <Row>
                <Col>
                    <label>Selecione o Status</label>
                    <Field
                        label="Status Produção"
                        name="status_producao_id"
                        id="status_producao_id"
                        enableFieldForm={true}
                        disabled
                        endPoint="status-producao/options"
                        component={SelectAutoComplete}
                        error={<ErrorMessage name="status_producao_id" />}
                    />
                </Col>
            </Row> 
            <Row>
                <Col>
                    <Field
                        label="Quantidade"
                        name="quantidade"
                        id="quantidade"
                        disabled
                        component={InputText}
                        error={<ErrorMessage name="quantidade" />}
                    />
                </Col>
            </Row>           
        </>
    );
}

export default function EstoqueProducao() {

    async function remanejarEstoqueProduto(id, produto_id) {
        const check = await Message.confirmation("Deseja remanejar os itens de Produção para o Estoque de Produto?");
        if(check) {
            await Api('post', 'estoque-producao/remanejar-estoque-produto', '', {
                estoque_producao_id: id,
                produto_id: produto_id
            });
            Message.success("Produto Remanejado com Sucesso.");
        }
    }

    return (
        <Crud 
            title="Estoque de Produção"
            endPoint="estoque-producao"
            searchFieldName='search'
            emptyObject={{
                produto_id: '',
                status_producao_id: '',
                quantidade: ''
            }}
            fields={[
                { name: 'name', label: 'Produto' },
                { name: 'name', label: 'Status' },
                { name: 'name', label: 'Quantidade' },
            ]}
            remanejarEstoqueProduto={(id, produto_id) => remanejarEstoqueProduto(id, produto_id)}
            fieldsHtml={({ item, remanejarEstoqueProduto }) => (
                <>
                    <td>
                        {item.produto.titulo} <br />
                        {item.is_finally && item.quantidade > 0 && 
                            <Button onClick={() => remanejarEstoqueProduto(item.id, item.produto.id)} variant="danger" size="sm">Remanejar para Estoque de Produtos</Button>
                        }
                    </td>
                    <td>
                        {item.status.nome}
                    </td>
                    <td>{item.quantidade >= 0 ?
                            item.quantidade 
                        : 
                            <span className='red'>{item.quantidade}</span>
                        }
                    </td>
                </>
            )}
            validation={(yup) => {
                return {
                    name: yup.string().required("Campo obrigatório"),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
