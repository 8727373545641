const Util = {

    redirect: () => {
        window.location.href = window.location.origin;
    },
    
    removeToken: () => {
        localStorage.removeItem("SYS@TOKEN");
    },
    
    setToken: (token) => {
        localStorage.setItem("SYS@TOKEN", token);
    },

    getToken: () => {
        const value = localStorage.getItem("SYS@TOKEN");
        return value;
    },

    Conditional: props => {
        const { test, children } = props;
        if(test) {
            return children;
        }
        return null;
    },
    getStatusSpan: status => {
        let span = '';
        switch(status) {
            case 'open':
                span = <span className="status status-open">Aberto</span>;
                break;
            case 'waiting':
                span = <span className="status status-waiting">Aguardando Aprovação</span>;
                break;
            case 'pending':
                span = <span className="status status-pending">Pendente</span>;
                break;
            case 'approved':
                span = <span className="status status-approved">Aprovado</span>;
                break;
            case 'production':
                span = <span className="status status-production">Em produção</span>;
                break;
            case 'travel':
                span = <span className="status status-travel">Em Viagem</span>;
                break;
            case 'finished':
                span = <span className="status status-finished">Finalizado</span>;
                break;
            case 'cancel':
                span = <span className="status status-cancel">Cancelado</span>;
                break;
            default: 
                span = '';
                break;
        }
        return span;
    }
};

export default Util;