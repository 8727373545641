import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import Pagination from 'src/components/Pagination';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';
import ReportPdf from 'src/components/ReportPdf';
import Message from 'src/core/Message';

export default function Clientes() {

    const [dataIni, setDataIni] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [page, setPage] = useState(0);
    const [clientes, setClientes] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [pagination, setPagination] = useState({});  
    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState({
        has_tabela: true,
        all_clientes: false,
    });
  
    async function getClientes() {
        const response = await Api('get', 'clients', '', {
            params: {
                page: page,
                ...params
            }
        });        
        setClientes(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    async function agrupar() {
        if(!selected.length) {
            Message.infor("Selecione pelo menos um pedido.");
            return false;
        }
        setShowPdf(true);
    }

    useEffect(() => {
        getClientes();
    }, [page, params]);

    useEffect(() => {
        if(!dataIni && !dataFim) {
            // getTotais();
        }
    }, [dataIni, dataFim]);

    return (
        <div className="" role="main">
            <ReportPdf 
                title="Clientes"
                endPoint="clientes"
                params={{
                    clientes: selected,
                    ...params
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            <h4>Clientes</h4>
            <Row>
                <Col>
                    <Button style={{ float: 'right' }} size="sm" variant="success" onClick={() => agrupar()}>Gerar PDF</Button>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <div className="form-group">
                        <label>Situação</label>
                        <select 
                            name="situacao" 
                            className="form-control"
                            onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})}
                        >
                            <option value="">Selecione</option>
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Bairro</label>
                        <input type="text" className="form-control" name="bairro" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Cidade</label>
                        <input type="text" className="form-control" name="cidade" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Estado</label>
                        <input type="text" className="form-control" name="estado" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Vendedor</label>
                        <input type="text" className="form-control" name="vendedor" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label id="tabela">
                            <input 
                                id="tabela"
                                checked={params.has_tabela}
                                type="checkbox"
                                name="has_tabela"
                                onChange={e => setParams({...params, ...{ [e.target.name]: e.target.checked }})}
                            />
                            &nbsp;
                            Possui Tabela de Preço?
                        </label>
                    </div>

                </Col>
                <Col>
                    <div className="form-group">
                        <label id="all_clientes">
                            <input 
                                id="all_clientes"
                                checked={params.all_clientes}
                                type="checkbox"
                                name="all_clientes"
                                onChange={e => {
                                    if(e.target.checked) {
                                        let lis = clientes.map(item => item.id);
                                        setSelected([...lis]);
                                    } else {
                                        setSelected([]);
                                    }
                                    setParams({...params, ...{ [e.target.name]: e.target.checked }});
                                }}
                            />
                            &nbsp;
                            Todos os Clientes?
                        </label>
                    </div>

                </Col>
            </Row>
            {!clientes.length && 'Nenhum resultado'}
            <Table className={!clientes.length && 'none'}>
                    <tr>
                        <th>Razão Social</th>
                        <th>CNPJ</th>
                        <th>Possui Tabela de Preço</th>
                    </tr>
                    {clientes.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td>
                                <strong>Razão Social: </strong> {item.razao_social} <br />
                                <strong>Bairro: </strong> {item.bairro} - <strong>Cidade: </strong> {item.cidade} - <strong>Estado: </strong> {item.uf} <br />
                                <strong>Vendedor: </strong>{item.vendedor.name}
                            </td>
                            <td>{item.cnpj ? item.cnpj : item.cpf}</td>
                            <td>{item.tabela_id ? 'Sim' : 'Não'}</td>
                        </tr>
                    ))}
            </Table>
            <hr />
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
        </div>
    );
}
