import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText } from 'src/components/FormHelper/Input';
import Pagination from 'src/components/Pagination';
import ReportPdf from 'src/components/ReportPdf';
import Api from 'src/core/api';
import Helpers from 'src/core/Helpers';

const FormWrapper = ({ Field, ErrorMessage, values }) => {
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const [showPdf, setShowPdf] = useState(false);
    
    async function getProdutos() {
        if(values.id) {
            const response = await Api('get', `produtos/list-produtos-by-porcentagem/${values.id}`, '', {
                params: {
                    page
                }
            });        
            setPagination(response);
            setList(response.data);
        }
        
    }

    useEffect(() => {
        getProdutos();
    }, [page]);

    return (
        <>
            <ReportPdf 
                title="Tabela de Preços"
                endPoint="tabelaProduto"
                params={{
                    tabela_id: values.id
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            {values.id &&
                <Row>
                    <Col>
                        <Button
                            style={{ float: 'right' }} 
                            size="sm" variant="success" onClick={() => setShowPdf(true)}>
                                Gerar PDF
                        </Button>
                    </Col>
                </Row>
            }
            <Row>
                <Col md={1}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col md={9}>
                    <Field 
                        required
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Col>
                <Col md={2}>
                    <Field 
                        required
                        id="porcentagem"
                        label="Porcentagem"
                        name="porcentagem"
                        component={InputText}
                        error={<ErrorMessage name="porcentagem" />}
                    />
                </Col>
            </Row> 
            <Row>
                <Col md={12}>
                    <h6>Composição dos Items Abaixo</h6>
                    <Table>
                        <thead>
                            <tr>
                                <th>Título</th>
                                <th>Tipo Unidade</th>
                                <th>Quantidade</th>
                                <th>Preço</th>
                                <th>Preço + Porcentagem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.titulo}</td>
                                    <td>{item.tipo_unidade}</td>
                                    <td>{item.quantidade}</td>
                                    <td>{Helpers.converterFloatReal(item.valor)}</td>
                                    <td>{Helpers.converterFloatReal(item.preco_total)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        changePage={data => setPage(data.page)} 
                        data={pagination}                            
                    />
                </Col>
            </Row>           
        </>
    );
}

export default function TablePrices() {
    return (
        <Crud 
            title="Tabela de Preços"
            endPoint="tabela-precos"
            emptyObject={{
                name: '',
                porcentagem: '',
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'name', label: 'Nome' },
                { name: 'porcentagem', label: 'Porcentagem' },
            ]}
            validation={(yup) => {
                return {
                    name: yup.string().required("Campo obrigatório"),
                    porcentagem: yup.string().required("Campo obrigatório"),
                    
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
