import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText, Button } from 'src/components/FormHelper/Input';
import Api from 'src/core/api';
import { Col, Row } from 'react-bootstrap';
import Util from 'src/core/Util';
import Message from 'src/core/Message';

export default function Login() {

    async function handleSubmit(values, actions) {
        const response = await Api('post', 'login', '', values);
        if(response) {
            Util.setToken(response.token);
            Message.success(response.message);
            setTimeout(() => {
                Util.redirect();
            }, 1000);
            actions.setSubmitting(false);
        }
    }

    const validation = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
        password: Yup.string().required('Campo obrigatório')
    });

    return (
        <div id="login" style={{ background: '#F7F7F7' }}>
            <div>
                {/* <a href className="hiddenanchor" id="signup"></a>
                <a href className="hiddenanchor" id="signin"></a> */}

                <div className="login_wrapper" style={{ marginTop: window.innerWidth < 500 ? '100px' : '' }}>
                    <div className="animate form login_form">
                    <section className="login_content">
                        <Formik
                            enableReinitialize
                            onSubmit={handleSubmit}
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={validation}
                        >
                            {(objects) => (
                                <form onSubmit={objects.handleSubmit} style={{ textAlign: 'left' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src="http://parlumin.com.br/img/core-img/logo3.png" />
                                    </div>
                                    <h2 style={{ textAlign: 'center' }}>Acesso SIS - Parlumin</h2>
                                    <Field 
                                        label="E-mail"
                                        name="email"
                                        id="email"
                                        type="email"
                                        required
                                        component={InputText}
                                        style={{ textTransform: 'lowercase' }}
                                        error={<ErrorMessage name="email" />}
                                    />
                                    <Field 
                                        label="Senha"
                                        name="password"
                                        id="password"
                                        type="password"
                                        required
                                        style={{ textTransform: 'lowercase' }}
                                        component={InputText}
                                        error={<ErrorMessage name="password" />}
                                    />
                                    <br />
                                    <Row>
                                        <Col>
                                            <a href>Esqueceu sua senha?</a>
                                            <a href>Dúvidas</a>
                                        </Col>
                                        <Col>
                                            <div style={{ textAlign: 'right' }}> 
                                                <Button variant="primary" name="Acessar" size="sm" type="submit" />
                                            </div> 
                                        </Col>
                                    </Row>
                                    
                                </form>
                            )}
                        </Formik>
                    </section>
                    </div>
                </div>
            </div>
        </div>
    );

}