import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Table, Modal, Dropdown, OverlayTrigger } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { GrAdd, GrView } from 'react-icons/gr';
import { InputMask, InputText, Select } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Api from 'src/core/api';
import Message from 'src/core/Message';
import Helpers from 'src/core/Helpers';
import PaperOrder from 'src/components/PaperOrder';
import FinishOrder from 'src/components/FinishOrder';
import AlterarStatus from './AlterarStatus';
import { AiOutlineEdit } from 'react-icons/ai';
import HelperContext from 'src/context/helper';
import { BsCardChecklist } from 'react-icons/bs';
import Tooltips from 'src/components/Tooltips';
import FilterClientOrOrder from '../Helpers/FilterClientOrOrder';
import AlterarItemPedido from './AlterarItemPedido';
import useDebounce from 'src/components/useDebounce';
import Util from 'src/core/Util';
import { AuthorizationMenuListar } from 'src/layouts/Menu';

const FormWrapper = ({ view, values, Field, ErrorMessage, setFieldValue, handleEdit, loadData }) => {

    const STATUS_ABERTO = 'open';
    const STATUS_AGUARDANDO = 'waiting';
    const STATUS_PENDENTE = 'pending';
    const STATUS_APROVADO = 'approved';
    const STATUS_PRODUCAO = 'production';
    const STATUS_VIAGEM = 'travel';
    const STATUS_FINALIZADO = 'finished';
    const STATUS_CANCELADO = 'cancel';
    
    const [produto, setProduto] = useState(null);
    const [show, setShow] = useState(false);
    const [showFinish, setShowFinish] = useState(false);
    const [listProduto, setListProduto] = useState([]);
    const [listItens, setListItens] = useState([]);
    const [params, setParams] = useState({});
    const [clientes, setClientes] = useState([]);
    
    
    const [status, setStatus] = useState(null);
    
    const [showModalItemPedido, setShowModalItemPedido] = useState(false);
    const [itemPedidoId, setItemPedidoId] = useState(null);
    const [showStatus, setShowStatus] = useState(false);
    const [showHistorico, setShowHistorico] = useState(false);
    const [showQuantidade, setShowQuantidade] = useState(false);
    const [dataQuantidade, setDataQuantidade] = useState(null);
    const [colaborador, setColaborador] = useState([]);
    const { setViewPedido, setViewHistoryPedido } = useContext(HelperContext);
    const debouncedSearchTerm = useDebounce(params, 500);
    const [fieldsFree, setFieldsFree] = useState([]);

    async function getColaborador() {
        const response = await Api('get', 'colaborador/options', '', {});        
        setColaborador(response.data);
    }


    async function addClient(cliente) {
        setFieldValue('cliente_id', cliente.value);
    }

    async function getItens() {
        const response = await Api('get', 'itens-pedidos', '', {
            params: {
                pedido_id: values.id
            }
        });        
        setListItens(response.data);
    }
    
    async function updateQuantidade() {
        if(dataQuantidade && dataQuantidade.id && dataQuantidade.quantidade) {
            await Api('put', 'itens-pedidos/'+dataQuantidade.id, '', {
                quantidade: dataQuantidade.quantidade
            });  
            handleEdit({ id: values.id });
            getItens();
            setDataQuantidade(null);
            setShowQuantidade(false);
        } else {
            Message.infor("Por favor, informe a quantidade.");
        }
        
    }

    async function addProduto() {

        if(!produto) {
            Message.infor("Por favor, selecione o produto");
            return null;
        }

        if(!values.quantidade) {
            Message.infor("Por favor, informe a quantidade");
            return null;
        }

        await Api('post', 'itens-pedidos', '', {
            pedido_id: values.id,
            produto_id: produto,
            quantidade: values.quantidade,
        });        
        // setListProduto([...listProduto, { id: produto, quantidade: values.quantidade }]);
        setFieldValue('quantidade', 0);
        setFieldValue('produto_id', '');
        handleEdit({id: values.id});
        setProduto(null);
        getItens();
    }

    async function removerItem(item_id) {
        // alert(item_id);
        const check = await Message.confirmation("Deseje remover este item?");
        if(check) {
            await Api('delete', `itens-pedidos/${item_id}`, '', {});        
            setFieldValue('quantidade', 0);
            setProduto(null);
            getItens();
            Message.success("Item removido com Sucesso");
        }
        
    }

    async function handleProduto(produto) {
        setProduto(produto.value);
    }

    async function updateStatus(_status) {
        setShowStatus(true);
        setStatus(_status);
        // const check = await Message.confirmation("Deseja aprovar este pedido?");
        // if(check) {
        //     await Api('put', `pedidos/${values.id}`, '', {
        //         status: status
        //     });        
        //     Message.success("Pedido aprovado com sucesso.");
        // }
        
    }

    async function getClientes() {
        const response = await Api('get', 'clients', '', {
            params: {
            ...params
            }
        });        
        setClientes(response.data);
    }

    useEffect(() => {
        getClientes();
    }, [debouncedSearchTerm]);
   

    useEffect(() => {
        if(view === 'edit') {
            getItens();
            getColaborador();
        }
    }, []);

    return (
        <>
            <Row>
                
                {view === 'new' ? 
                    <Col md={12}>
                        <FilterClientOrOrder params={params} setParams={setParams} />
                        <label>Selecione o Cliente</label>
                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>
                                        Dados
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientes.map((item, index) => (
                                    <tr 
                                        key={index} 
                                        style={{ background: values.cliente_id === item.id ? '#CCC' : '' }}
                                        onClick={() => setFieldValue('cliente_id', item.id)} >
                                        <td>
                                            <strong> {item.razao_social}</strong> <br />
                                            <strong>CNPJ:</strong> {item.cnpj} <br />
                                            <strong>Endereço:</strong> {item.logradouro}, {item.numero} - {item.bairro} - {item.cidade} <br />
                                            <strong>Estado/CEP:</strong> {item.uf} - {item.cep}<br />
                                            <strong>Vendedor(a):</strong>  {item.vendedor.name}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/* <Field
                            label="Clientes"
                            name="cliente_id"
                            id="cliente_id"
                            endPoint="clientes/options"
                            handleChange={addClient}
                            component={SelectAutoComplete}
                        /> */}
                    </Col>
                : 
                <>
                    <Col md={8}>
                        <h4>
                            <strong>Código do Pedido</strong>: {values.codigo} <br />
                        </h4>
                        <strong>Cliente</strong>: {values.cliente.razao_social} <br />
                        <strong>Fantasia</strong>: {values.cliente.nome_fantasia} <br />
                        <strong>CNPJ</strong>: {values.cliente.cnpj} <br />
                        <strong>E-mail</strong>: {values.cliente.email} <br />
                        <strong>Forma de Pagamento: </strong>: {values.forma_pagamento && values.forma_pagamento.name} <br />
                        <strong>Prazo de Pagamento: </strong>: {values.tipo_pagamento && values.tipo_pagamento.descricao} <br />
                        <strong>Desconto: </strong> 
                        <span style={{ color: 'red' }}>
                            {values.desconto_maior ? <>
                                Foi aplicado um desconto maior que {values.tipo_pagamento.desconto}%. O desconto foi de {values.desconto}%.
                            </> : values.desconto_maior} 
                        </span>
                        <br />
                    </Col>
                    <Col md={4} style={{ textAlign: 'right' }}>
                        <AuthorizationMenuListar action={'pedidos'} btnAction='editar'>
                            <Dropdown>
                                <Dropdown.Toggle size="sm" variant="success" id="dropdown-basic">
                                    Ações
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setViewPedido(values.id)}>Ver Pedido</Dropdown.Item>
                                    {values.status !== STATUS_AGUARDANDO && values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_APROVADO)}>Aprovar Pedido</Dropdown.Item>
                                    }
                                    {/* {values.status === STATUS_AGUARDANDO && values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_APROVADO)}>Aprovar Pedido</Dropdown.Item>
                                    }
                                    {values.status === STATUS_PENDENTE && values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_APROVADO)}>Aprovar Pedido</Dropdown.Item>
                                    } */}
                                    <Dropdown.Item onClick={() => setViewHistoryPedido(values.id)}>Listar Historico</Dropdown.Item>
                                    {values.status !== STATUS_FINALIZADO && 
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_PENDENTE)}>Lançar Pendência</Dropdown.Item>
                                    }
                                    {values.status === STATUS_APROVADO && values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_PRODUCAO)}>Em Produção</Dropdown.Item>
                                    }
                                    {values.status === STATUS_PRODUCAO && values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_VIAGEM)}>Em Viagem</Dropdown.Item>
                                    }                              
                                    {values.status !== STATUS_FINALIZADO && values.status !== STATUS_CANCELADO && !values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => setShowFinish(true)}>Fechar Pedido</Dropdown.Item>
                                    }
                                    {values.forma_pagamento_id &&
                                        <Dropdown.Item onClick={() => setShowFinish(true)}>Alterar Forma de Pagamento</Dropdown.Item>
                                    }
                                    {values.status !== STATUS_FINALIZADO && values.status !== STATUS_CANCELADO && 
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_FINALIZADO)}>Entregar Pedido</Dropdown.Item>
                                    }
                                    {values.status !== STATUS_FINALIZADO && 
                                        <Dropdown.Item onClick={() => updateStatus(STATUS_CANCELADO)}>Cancelar Pedido</Dropdown.Item>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </AuthorizationMenuListar>
                    </Col>
                </>
                }
            </Row>
            {view === 'edit' && 
                <>
                <Row>
                    <Col md={4}>
                        <Field 
                            required
                            id="vendedor_id"
                            label="Vendedor"
                            name="vendedor_id"
                            placeholder="Selecione"
                            component={Select}
                            list={colaborador}
                            error={<ErrorMessage name="vendedor_id" />}
                        />
                    </Col>
                    <Col md={4}>
                        <Field 
                            required
                            id="tipo"
                            label="Tipo de Pedido"
                            name="tipo"
                            placeholder="Selecione"
                            component={Select}
                            list={[
                                { value: 'P', label: 'Pedido' },
                                { value: 'O', label: 'Orçamento' },
                                { value: 'R', label: 'Reparo' },
                            ]}
                            error={<ErrorMessage name="tipo" />}
                        />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col><h5>Adicionar Produtos</h5></Col>
                </Row>
                <Row>
                    <Col s={12} md={8} l={6}>
                        <label>Produtos</label>
                        <Field
                            label="Produtos"
                            name="produto_id"
                            id="produto_id"
                            enableFieldForm={true}
                            endPoint="produtos/options"
                            params={{
                                tipo: 'produto'
                            }}
                            handleChange={handleProduto}
                            component={SelectAutoComplete}
                        />
                    </Col>
                    <Col s={12} md={2}>
                        <Field 
                            id="quantidade"
                            label="Quantidade"
                            name="quantidade"
                            type="number"
                            component={InputText}
                            error={<ErrorMessage name="quantidade" />}
                        />
                    </Col>
                    
                    <Col md={2} >
                        <AuthorizationMenuListar action={'pedidos'} btnAction='editar'>
                            <Button
                                variant="warning"
                                style={{ marginTop: '30px' }} 
                                size="sm" type="button" onClick={() => addProduto()}>Adicionar</Button>
                        </AuthorizationMenuListar>
                    </Col>
                    
                    <Table>
                        <thead>
                            <tr>
                                <td style={{ width: '900px' }}>Dados</td>
                                <td style={{ width: '900px' }}>Quantidade</td>
                                <td style={{ width: '10px' }}>Ações</td>
                            </tr>
                        </thead>
                        <tbody>
                            {listItens.filter(item => item.exibir).map((item, key) => (
                                <tr key={key}>
                                    <td style={{ width: '90%' }}>
                                        <strong>Cód.: </strong>{item.id} <br />
                                        <strong>Produto:</strong> {item.produto.titulo} <br />
                                        Quantidade: {item.quantidade} <br />
                                        Valor: {Helpers.converterFloatReal(item.preco)} <br />
                                        <strong>SubTotal:</strong> {Helpers.converterFloatReal(item.quantidade * item.preco)} <br />
                                        <AuthorizationMenuListar action={'pedidos'} btnAction='editar'>
                                            <a href onClick={() => {
                                                setItemPedidoId(item.id);
                                                setShowModalItemPedido(true);
                                            }}>Editar</a>
                                        </AuthorizationMenuListar>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <a href onClick={() => {
                                                setShowQuantidade(true);
                                                setDataQuantidade({ id: item.id, quantidade: item.quantidade });
                                            }}>
                                                {item.quantidade}
                                            </a>
                                        </div>
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        <AuthorizationMenuListar action={'pedidos'} btnAction='editar'>
                                            <Button type="button" variant="danger" size="sm" onClick={() => removerItem(item.id)}>X</Button>
                                        </AuthorizationMenuListar>
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody>
                    </Table>
                    {/* Valores */}
                    <Table hover striped>
                        <tbody>
                            <tr>
                                <td>Total</td>
                                <td style={{  textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(values.total)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Desconto</td>
                                <td style={{  textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(parseFloat(values.total || 0) * parseFloat(values.desconto) / 100)}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '16px' }}>
                                    <strong>
                                        Total com Desconto
                                    </strong>
                                </td>
                                <td style={{ fontSize: '16px', textAlign: 'right' }}>
                                    <strong>
                                        {Helpers.converterFloatReal(parseFloat(values.total ? values.total : 0) - (parseFloat(values.total || 0) * parseFloat(values.desconto) / 100))}
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            
                {values.status === '5'
                    &&
                    <Row>
                        <Col>
                            <Button size="sm" variant="success" onClick={() => updateStatus(STATUS_FINALIZADO)}>Fechar Pedido</Button>
                        </Col>
                    </Row>
                }
                    <FinishOrder 
                        showFinish={showFinish}
                        setShowFinish={setShowFinish} 
                        handleEdit={handleEdit}
                        pedido_id={values.id} 
                    />
                    <AlterarStatus 
                        showStatus={showStatus} 
                        setShowStatus={setShowStatus} 
                        pedido_id={values.id}
                        handleEdit={handleEdit}
                        status={status}
                        loadData={loadData}
                    />
                    <AlterarItemPedido 
                        show={showModalItemPedido} 
                        setShow={setShowModalItemPedido} 
                        item_pedido_id={itemPedidoId}
                        pedido_id={values.id}
                        handleEdit={handleEdit}
                        status={status}
                        getItens={getItens}
                    />
                    <Modal
                        show={showQuantidade}
                        onHide={() => setShowQuantidade(false)}
                        dialogClassName=""
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Atualizar Quantidade
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label>Quantidade</label>
                                <input 
                                    type="number" 
                                    className="form-control"
                                    value={dataQuantidade ? dataQuantidade.quantidade : 0}
                                    onChange={e => setDataQuantidade({...dataQuantidade, ...{ quantidade: e.target.value }})}
                                />
                            </div> 
                            <Button style={{ float: 'right' }} onClick={() => updateQuantidade()} variant="success" size="sm">Lançar</Button>
                        </Modal.Body>
                    </Modal>
                </>
            }
            {/* <PaperOrder pedido_id={values.id} show={show} setShow={setShow}/> */}
        </>
    );
}

export default function Orders() {

    const { setViewPedido, setViewHistoryPedido, setViewAlterStatus} = useContext(HelperContext);

    return (
        <Crud 
            title="Pedidos"
            endPoint="pedidos"
            searchFieldName="search"
            titleBtnSave="Abrir Pedido"
            titleBtnCancel="Voltar"
            saveContinueForm={true}
            emptyObject={{
                cliente_id: '',
                desconto: 0,
                total: 0,
                tipo: 'P',
            }}
            validation={(yup) => {
                return {
                    cliente_id: yup.string().required("Campo obrigatório"),
                };
            }}
            fields={[
                { name: 'id', label: 'Dados', classBody: 'min-width' },
                { name: 'id', label: 'Ações', classBody: 'min-width' },
                
            ]}
            fieldsHtml={({item }) => (
                <>
                    <td>
                        <strong>Código:</strong> {item.codigo} <br />
                        <strong>Cliente:</strong> {item.cliente.razao_social} | {item.nome_fantasia} <br />
                        <strong>CNPJ/CPF:</strong> {item.cliente.cnpj} | {item.cliente.cpf} <br />
                        <span class="content-color">
                            <strong>Fantasia: </strong>{item.cliente.nome_fantasia} | <strong>CEP: </strong>{item.cliente.cep} | <strong>Bairro: </strong>{item.cliente.bairro} | <strong>Cidade: </strong>{item.cliente.cidade} <br />
                        </span>                       
                        {item.data} <br />
                        Status: {Util.getStatusSpan(item.status)}
                        <br />
                        <span style={{ color: 'red' }}>
                            {item.desconto_maior ? <>
                                Foi aplicado um desconto maior que {item.item_tipo_pagamento.desconto}%. O desconto foi de {item.desconto}%.
                            </> : ''} 
                        </span>
                    </td>
                    <td>
                        <Tooltips text="Visualizar Pedido">
                            <GrView size="1.5em" onClick={() => setViewPedido(item.id)} />
                        </Tooltips>
                        &nbsp;&nbsp;
                        <Tooltips text="Ver Histórico de Pedido">
                            <BsCardChecklist size="1.5em" onClick={() => setViewHistoryPedido(item.id)} />
                        </Tooltips>
                        &nbsp;&nbsp;
                        <Tooltips text="Alterar Status">
                            <BsCardChecklist size="1.5em" onClick={() => setViewAlterStatus(item.id)} />
                        </Tooltips>
                    </td>
                </>
            )}
            FormSearch={({ params, setParams }) => (
                <Row>
                    <Col>
                        <div className="form-group">
                            <input class="form-control" placeholder="CNPJ/CPF" name="cnpj" type="text" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <input class="form-control" placeholder="Bairro" name="bairro" type="text" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <input class="form-control" placeholder="Cidade" name="cidade" type="text" onChange={e => setParams({...params, ...{ [e.target.name]: e.target.value }})} />
                        </div>
                    </Col>
                </Row>
            )}
            FormWrapper={FormWrapper}
        />
    );
}

