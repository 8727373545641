import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Modal } from 'react-bootstrap';
import Api from 'src/core/api';
import ReportPdf from 'src/components/ReportPdf';
import Helpers from 'src/core/Helpers';
import Message from 'src/core/Message';
import Pagination from 'src/components/Pagination';
import FilterGlobal from 'src/components/FilterGlobal';
import Util from 'src/core/Util';
import useDebounce from 'src/components/useDebounce';
export default function Agrupados() {

    const [dataIni, setDataIni] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [page, setPage] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [showPdf, setShowPdf] = useState(false);
    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);
    const [params, setParams] = useState({});
    const [pagination, setPagination] = useState([]);
    const debouncedSearchTerm = useDebounce(params, 500);
    async function getPedidos() {
        const response = await Api('get', 'pedidos', '', {
            params: {
                page,
                ...params
            }
        });        
        setPedidos(response.data);
        setPagination(response);
    }

    async function handleSelected(id) {
        if(selected.includes(id)) {
            let lis = selected.filter(item => item !== id);
            setSelected([...lis]);
        } else {
            setSelected([...selected, id]);
        }
    }

    async function agrupar() {
        if(!selected.length) {
            Message.infor("Selecione pelo menos um pedido.");
            return false;
        }
        // const response = await Api('get', 'itens-pedidos/agrupar', '', {
        //     params: {
        //         pedidos: selected,
        //     }
        // });        
        // setProdutos(response.data);
        // setShow(true);
        setShowPdf(true);
    }

    useEffect(() => {
        getPedidos();
    }, [page]);

    useEffect(() => {
        getPedidos();
    }, [debouncedSearchTerm]);
   

    return (
        <div className="" role="main">
            <ReportPdf 
                title="Pedidos Agrupados"
                endPoint="agrupados"
                params={{
                    pedidos: selected,
                    ...params
                }}
                show={showPdf}
                setShow={setShowPdf}
            />
            <h4>Pedidos</h4>
            <Row>
                <Col>
                    <Button style={{ float: 'right' }} size="sm" variant="success" onClick={() => agrupar()}>Gerar PDF</Button>
                </Col>
            </Row>
            <FilterGlobal params={params} setParams={setParams} setSelected={setSelected} list={pedidos}/>
            {!pedidos.length && 'Nenhum resultado'}
            <Table className={!pedidos.length && 'none'}>
                    <tr>
                        <th width="10%">Código</th>
                        <th>Status</th>
                        <th>Cliente</th>
                        <th>Data do Pedido</th>
                    </tr>
                    {pedidos.map((item, index) => (
                        <tr onClick={() => handleSelected(item.id)} className={selected.includes(item.id) ? 'selected' : ''}>
                            <td>{item.codigo}</td>
                            <td>
                                {Util.getStatusSpan(item.status)}
                            </td>
                            <td>
                                <strong>Razão Social: </strong> {item.cliente.razao_social} <br />
                                <strong>Bairro: </strong> {item.cliente.bairro} - <strong>Cidade: </strong> {item.cliente.cidade} - <strong>Estado: </strong> {item.cliente.uf} <br />
                                <strong>Vendedor: </strong>{item.vendedor.name}
                            </td>
                            <td>{item.data}</td>
                        </tr>
                    ))}
            </Table>
            <Pagination
                changePage={data => setPage(data.page)} 
                data={pagination}                            
            />
            <hr />
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-70w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" style={{ width: '100%' }}>
                    <Row>
                        <Col md={10}>
                            <h4>Pedidos Agrupados</h4>
                        </Col>
                        <Col md={2}>
                            <a href 
                            onClick={() => {
                                var conteudo = document.getElementById('print').innerHTML,
                                tela_impressao = window.open('');
                                tela_impressao.document.write(conteudo);
                                tela_impressao.window.print();
                                tela_impressao.window.close();
                            }}>
                                <h4>Imprimir</h4>
                            </a>
                        </Col>
                    </Row>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body id="print">
                    <Table style={{ border: '1px solid  #CCC', width: '100%' }}>
                        <tr>
                            <th>Descrição</th>
                            <th  style={{ textAlign: 'right' }}>Quantidade</th>
                        </tr>
                        {produtos.map((item, index) => (
                            <tr>
                                <td>{item.produto.titulo}</td>
                                <td style={{ textAlign: 'right' }}>{item.quantidade}</td>
                            </tr>
                        ))}
                        
                    </Table>
                </Modal.Body>
            </Modal>
            
            <hr />
        </div>
    );
}
