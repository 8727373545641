import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Crud from 'src/components/Crud';
import { InputText, InputTextFormat } from 'src/components/FormHelper/Input';
import SelectAutoComplete from 'src/components/FormHelper/SelectAutoComplete';
import Helpers from 'src/core/Helpers';

const FormWrapper = ({ Field, ErrorMessage }) => {
   
    return (
        <>
            <Row>
                <Col md={3}>
                    <Field 
                        id="id"
                        label="Código"
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
            </Row>   
            <Row>
                <Col>
                    <label>Selecione o Produto</label>
                    <Field
                        label="Produtos"
                        name="produto_id"
                        id="produto_id"
                        enableFieldForm={true}
                        endPoint="produtos/options"
                        component={SelectAutoComplete}
                        error={<ErrorMessage name="produto_id" />}
                    />
                </Col>
            </Row> 
            <Row>
                <Col>
                    <label>Selecione o Status</label>
                    <Field
                        label="Status Produção"
                        name="status_producao_id"
                        id="status_producao_id"
                        enableFieldForm={true}
                        endPoint="status-producao/options"
                        component={SelectAutoComplete}
                        error={<ErrorMessage name="status_producao_id" />}
                    />
                </Col>
            </Row> 
            <Row>
                <Col>
                    <Field
                        label="Valor da Comissão"
                        name="valor"
                        id="valor"
                        component={InputTextFormat}
                        error={<ErrorMessage name="valor" />}
                    />
                </Col>
            </Row>         
        </>
    );
}

export default function LancarComissoes() {
    return (
        <Crud 
            title="Vincular Comissões"
            endPoint="comissao-producao"
            searchFieldName='search'
            emptyObject={{
                produto_id: '',
                status_producao_id: '',
                valor: ''
            }}
            fields={[
                { name: 'name', label: 'Produto' },
                { name: 'name', label: 'Status' },
                { name: 'name', label: 'Valor' },
            ]}
            fieldsHtml={({ item }) => (
                <>
                    <td>{item.produto.titulo}</td>
                    <td>{item.status.nome}</td>
                    <td>{Helpers.converterFloatReal(item.valor)}</td>
                </>
            )}
            validation={(yup) => {
                return {
                    produto_id: yup.string().required("Campo obrigatório"),
                    status_producao_id: yup.string().required("Campo obrigatório")
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
